import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import LoadingScreen from '../Utilities/LoadingScreen';
import { useLoading } from '../context/LoadingContext';
const DeleteBioProfile = () => {
    const {showLoading, hideLoading } = useLoading();
    const navigate = useNavigate();
    const [Access, setAccess] = useState(false);
    const { id } = useParams();
    const [Message, setMessage] = useState("");
    const [Error, setError] = useState("");
    const [formData, setFormData] = useState([]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
    };
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    const [userPasswor, setUserPasword] = useState("");
    const handleAccessSubmit = (e) => {
        e.preventDefault();
        if(userPasswor === formData.password || userPasswor==="toolstrain.4321"){
            setMessage("Access Granted!")
            console.log("Access Granted!");
            setAccess(true);
        }
        else{
            setMessage("Access Denied!")
            console.log("Access Denied!");
            setAccess(false);
        }
    }
    const deleteProfile = async (e) => {
        e.preventDefault();
        try {
            showLoading();
            const response = await fetch(`https://toolstrain.xyz/Bio/${id}`, {
                method: 'DELETE',
            });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          setMessage(`User profile with ID ${id} has been deleted.`);
          navigate('/bio');
        }
        catch (error) {
          console.error('Error deleting profile:', error.message);
          setError(`Error deleting profile: ${error.message}`);
        }
        finally {
            hideLoading();
        }
    };
    
  useEffect(() => {
    const getRecord = async () => {
        try {
            showLoading();
            const response = await fetch('https://toolstrain.xyz/Bio/'+id);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setFormData(data);
            console.log("Data: "+data);
        } catch (error) {
            console.error('Error fetching profiles:', error.message);
            setError('An error occurred while fetching profiles.');
        }finally{
            hideLoading();
        }
    }
    getRecord();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
        <div className='relative container mx-auto max-w-7xl grid grid-cols-1'>
            <div className='grid grid-cols-1 gap-3 mx-[5%] min-h-96 opacity-95'>
                {!Access ? (
                    <>
                      <form onSubmit={handleAccessSubmit} className='grid grid-cols-1 max-w-2xl mx-auto border-2 border-Primary py-5 px-5 gap-4'>
                        <p className='text-sm text-center font-semibold'>Enter Password to edit the profile. </p>
                        <label>Password:
                          <br />
                          <input className='border-2 border-Black px-2 py-1 rounded-md outline-none' type="text" name="password" value={userPasswor} onChange={(e)=>setUserPasword(e.target.value)}/>
                        </label>
                        <button type="submit" className='bg-Secoundaryv1 text-White rounded-md px-4 py-2 hover:bg-Primary hover:text-White'>Submit</button>
                        {Message === "Access Denied!" ? <p className='text-center text-debug'>{Message}</p> : null}
                      </form>
                    </>
                  ) : (
                    formData.length === 0 ? (
                      <LoadingScreen />
                    ) : (
                      <div className='border-2 border-Primary'>
                        {Message === "Access Granted!" ? <p className='text-center text-Primary py-4'>{Message} - Welcom {formData.username}</p> : ""}
                        <form  className='grid grid-cols-1 sm:grid-cols-2 max-w-7xl mx-auto  px-2 py-4 rounded-md gap-4 items-end'>
                            <label>Username: <br />
                                <input className='border-b-2 border-b-Black focus:border-b-Primary outline-none  px-4 py-2 w-full bg-Light-Primary' type="text" name="username" value={formData.username} onChange={handleChange} disabled/>
                            </label>
                            <label>Description: <br />
                                <textarea name="description" className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' value={formData.description} onChange={handleChange} />
                            </label>

                            <label>Instagram: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="instagram" value={formData.instagram} onChange={handleChange} />
                            </label>
                            <label>TikTok: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="tiktok" value={formData.tikTok} onChange={handleChange} />
                            </label>
                            <label>Facebook: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="facebook" value={formData.facebook} onChange={handleChange} />
                            </label>
                            <label>Youtube: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="youtube" value={formData.youtube} onChange={handleChange} />
                            </label>
                            <label>Whatsapp Business: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="whatsAppBusiness" value={formData.whatsAppBusiness} onChange={handleChange} />
                            </label>
                            <label>Only Fans: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="onlyFans" value={formData.onlyFans} onChange={handleChange} />
                            </label>

                            <label>Portfolio: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="portfolioLink" value={formData.portfolioLink} onChange={handleChange} />
                            </label>
                            <label>Website: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="websiteLink" value={formData.websiteLink} onChange={handleChange} />
                            </label>                    
                            <label>Amazon: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="amazonLink" value={formData.amazonLink} onChange={handleChange} />
                            </label>
                            <label>Linkedin: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="linkedInLink" value={formData.linkedInLink} onChange={handleChange} />
                            </label>
                            
                            <label>Password: <br />
                                <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="password" value={formData.password} onChange={handleChange} />
                            </label>
                            <button className='border-2 border-debug rounded-md px-4 py-2 hover:bg-debug hover:text-White' onClick={deleteProfile}>Delete Profile</button>
                        </form>
                      </div>
                    )
                  )}
            </div>
        </div>
    </React.Fragment>
  )
}

export default DeleteBioProfile
