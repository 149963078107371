import React, { useRef, useState } from "react";
import base64 from "base-64";

const EncoderDecoder = () => {
  const [plainText, setPlainText] = useState('');
  const [encodedText, setEncodedText] = useState('');
  const [decodedText, setDecodedText] = useState('');
  const [message, setMessage] = useState('');
  const encodedTextareaRef = useRef(null);
  const decodedTextareaRef = useRef(null);
  const decodeBase64 = () => {
    try {
      if(encodedText){
        const decoded = base64.decode(encodedText);
        setDecodedText(decoded);
        setEncodedText("");
        setMessage('');
      }
      else{
        setMessage('Please Encode First !');
      }
      
    } catch (error) {
      console.error("Error decoding Base64:", error.message);
      setDecodedText("Error decoding Base64");
      setEncodedText("");
    }
  };


  const encodeToBase64 = () => {
    try {
      if(plainText)
      {
        const encoded = base64.encode(plainText);
        setEncodedText(encoded);
        setDecodedText("");
        setMessage('');
      }
      else{
        setMessage('Write Some Text To Encode !');
      }
    } catch (error) {
      console.error('Error encoding to Base64:', error.message);
      setEncodedText('Error encoding to Base64');
      setDecodedText("");
    }
  };
  const copyToClipboardEncodedText = () => {
    const textarea = encodedTextareaRef.current;
    if (textarea) {
      textarea.select();
      document.execCommand('copy');
      console.log('Text copied to clipboard!');
    }
  };
  const copyToClipboardDecodedText = () => {
    const textarea = decodedTextareaRef.current;
    if (textarea) {
      textarea.select();
      document.execCommand('copy');
      console.log('Text copied to clipboard!');
    }
  };

  return (
    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
        <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
            <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                Online Encoder and Decoder: Break the Language Barrier
                <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
            </h2>
            <h3 className='text-center text-sm sm:text-lg'>Break the language barrier with our online encoder and decoder. Communicate effectively in different languages.</h3>
        </div>
        <div className="grid grid-cols-1 max-w-5xl m-auto w-full gap-4">
            <textarea 
                rows={5}
                value={plainText}
                onChange={(e) => setPlainText(e.target.value)}
                placeholder="Enter text to encode here"
                className="grid grid-cols-1 justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White w-full">
            </textarea>
            <div className="grid grid-cols-[auto_auto]">
              <button
                  className='border-2 border-Primary px-4 py-2 my-1 mr-1 rounded-md text-White bg-Primary hover:bg-White hover:text-Primary'
                  onClick={encodeToBase64}
                >
                  Encode
              </button>
              <button
                  className='border-2 border-Primary px-4 py-2 my-1 mr-1 rounded-md text-White bg-Primary hover:bg-White hover:text-Primary inline-block'
                  onClick={decodeBase64}
                >
                  Decode
              </button>
            </div>
        </div>
        {encodedText && 
          <div className="grid w-full max-w-5xl m-auto">
            <h4 className="text-Primary py-2">Results: (Encoded into 64 Base)</h4>
            <textarea className="w-full overflow-auto border-2 border-Primary p-2 rounded-lg bg-White" rows={4} ref={encodedTextareaRef} value={`${encodedText}`}>
            </textarea>
            <button
                className='border-2 border-Primary px-2 py-1 my-3 rounded-md text-Primary hover:bg-Primary hover:text-White justify-self-end'
                onClick={copyToClipboardEncodedText}
              >
                <span className='hidden sm:inline-block'>Quick</span> <span className='inline-block'>Copy</span>
            </button>
          </div>
        }
        {decodedText && 
          <div className="grid w-full max-w-5xl m-auto">
            <h4 className="text-Primary py-2">Results: (Decoded into 64 Base)</h4>
            <textarea className="w-full overflow-auto border-2 border-Primary p-2 rounded-lg bg-White" rows={4} ref={decodedTextareaRef} value={`${decodedText}`}>
            </textarea>
            <button
                className='border-2 border-Primary px-2 py-1 my-3 rounded-md text-Primary hover:bg-Primary hover:text-White justify-self-end'
                onClick={copyToClipboardDecodedText}
              >
                <span className='hidden sm:inline-block'>Quick</span> <span className='inline-block'>Copy</span>
            </button>
          </div>
        }
         {message && <p className="text-center text-debug">{message}</p>}
    </div>
  );
};

export default EncoderDecoder;
