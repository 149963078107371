import React, { useEffect } from 'react'
import CategorySliderFrame from '../sections/CategorySliderFrame'
import CategorySliderFrame1 from '../sections/CategorySliderFrame1'
import WhyUs from '../sections/WhyUs'
import ConverttoPngTool from '../sections/Tool Section/ConverttoPngTool'
import { Helmet } from 'react-helmet';
import CategorySliderFrame3 from '../sections/CategorySliderFrame3'

const ConvertToPng = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Hassle free Convert image to Png by using Toolstrain free image converter with no signup or Credentials required! | Tools Train.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Hassle free Convert image to Png by using Toolstrain free image converter with no signup or Credentials required!" />
        <meta name="keywords" content="convert to PNG, image format converter, PNG image conversion, file format change, image compressor" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
      <div className='bg-Transparent1'>
        <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
            <div className=' grid grid-cols-1 mx-[5%] py-[25%] bg-hero-pattern bg-no-repeat bg-center bg-cover opacity-95'>
              <ConverttoPngTool />
            </div>
        </div>
      </div>
      <CategorySliderFrame />
      <CategorySliderFrame1 />
      <CategorySliderFrame3 />
      <WhyUs />
  </React.Fragment>
  )
}

export default ConvertToPng
