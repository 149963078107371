import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { P } from '../components/Paragraphs/P';
import { PLink } from '../components/Paragraphs/PLink';
import { H3 } from '../components/Headings/H3';
import { H2 } from '../components/Headings/H2';
export const PrivacyAndPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>ToolsTrain Terms of Service and Privacy Policy - User Agreement | Toolstrain.com</title>
        <meta charset="utf-8" />
        <meta name="description" content="Read our terms of service, privacy policy, and user agreement along with our suite of free online tools and web services." />
        <meta name="keywords" content="" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://toolstrain.com/"
              },
              "headline": "ToolsTrain Terms of Service and Privacy Policy - User Agreement",
              "description": "Read our terms of service, privacy policy, and user agreement along with our suite of free online tools and web services.",
              "image": "",  
              "author": {
                "@type": "Organization",
                "name": "toolstrain",
                "url": "https://toolstrain.com/"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "",
                "logo": {
                  "@type": "ImageObject",
                  "url": ""
                }
              },
              "datePublished": "14-06-2024"
            }
        `}
        </script>

        <meta property="og:title" content="ToolsTrain Terms of Service and Privacy Policy - User Agreement" />
        <meta property="og:site_name" content="toolstrain" />
        <meta property="og:url" content="https://toolstrain.com/" />
        <meta property="og:description" content="Read our terms of service, privacy policy, and user agreement along with our suite of free online tools and web services." />
        <meta property="og:type" content="" />
        <meta property="og:image" content="" />


        <meta name="twitter:card" content="app" />
        <meta name="twitter:site" content="@toolstrain" />
        <meta name="twitter:description" content="ToolsTrain offers a variety of free online tools and web services to help users simplify various tasks. Discover our productivity tools." />
        <meta name="twitter:app:name:iphone" content="toolstrain" />
        <meta name="twitter:app:id:iphone" content="" />
        <meta name="twitter:app:name:ipad" content="toolstrain" />
        <meta name="twitter:app:id:ipad" content="" />
        <meta name="twitter:app:name:googleplay" content="toolstrain" />
        <meta name="twitter:app:id:googleplay" content="" />
        <meta name="twitter:app:country" content="" />

      </Helmet>
      <div className='relative container mx-auto max-w-7xl grid grid-cols-1'>
        <div className='grid grid-cols-1 mx-[5%] mt-8 py-4'>
          <div className="bg-gray-100 h-auto flex items-center justify-center">
            <div className="grid bg-white p-8 rounded-lg shadow-md w-full gap-3" >
              <H2 txt="Privacy and Policy" />
              <P txt="A collection of web resources and services offered by ToolsTrain, Inc. The ToolsTrain platform, including all tools, features, and services provided through the ToolsTrain website and applications (collectively, the 'ToolsTrain Services') is governed by these Terms of Service (the 'Agreement')." />
              <ul className='list-disc pl-4'>
                <li>
                  <PLink>You accept this Agreement and our Privacy Policy by using or accessing the ToolsTrain Services. Access to and use of the ToolsTrain Services is not permitted if you do not accept this Agreement.</PLink>
                </li>
                <li>
                  <PLink>ToolsTrain Services are offered for your own, non-commercial use only.</PLink>
                </li>
                <li>
                  <PLink>You pledge to use the ToolsTrain Services in compliance with all relevant laws, rules, and regulations.</PLink>
                </li>
                <li>
                  <PLink>Use of the ToolsTrain services requires a minimum age of 13. Please do not use the ToolsTrain services if you are under 13.</PLink>
                </li>
              </ul>
              <H3 txt="Intellectual Property:" />
              <ul className='list-disc pl-4'>
                <li>
                  <PLink>Copyright, trademark, and other intellectual property laws protect ToolsTrain's ownership of the ToolsTrain Services, which include all content, features, and functionality.</PLink>
                </li>
                <li>
                  <PLink>You may not modify, copy, distribute, transmit, display, reproduce, or create derivative works from the ToolsTrain Services without our prior written permission.
                  </PLink>
                </li>
              </ul>
              <H3 txt="Third-Party Services and Content:" />
              <ul className='list-disc pl-4'>
                <li>
                  <PLink>The ToolsTrain Services may contain links to third-party websites or applications. ToolsTrain is not responsible for the availability, content, or privacy practices of those third-party services.
                  </PLink>
                </li>
                <li>
                  <PLink>Use of third-party services is subject to their respective terms of service and privacy policies.
                  </PLink>
                </li>
              </ul>
              <H3 txt="Termination and Suspension:" />
              <ul className='list-disc pl-4'>
                <li>
                  <PLink>ToolsTrain reserves the right to suspend or terminate your access to the ToolsTrain Services at any time, for any reason, without notice.
                  </PLink>
                </li>
                <li>
                  <PLink>Upon termination, your right to use the ToolsTrain Services will immediately cease, and ToolsTrain may delete any of your content or information that is stored on our servers.
                  </PLink>
                </li>
              </ul>
              <H3 txt="Disclaimer of Warranties:" />
              <ul className='list-disc pl-4'>
                <li>
                  <PLink>The ToolsTrain Services are provided "as is" and "as available" without warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                  </PLink>
                </li>
              </ul>
              <H3 txt="Limitation of Liability:" />
              <ul className='list-disc pl-4'>
                <li>
                  <PLink>In no event shall ToolsTrain be liable for any indirect, special, incidental, or consequential damages related to your use of the ToolsTrain services.
                  </PLink>
                </li>
                <li>
                  <PLink>ToolsTrain's total liability to you shall not exceed $100 USD.
                  </PLink>
                </li>
              </ul>
              <H3 txt="Governing Law and Dispute Resolution:" />
              <ul className='list-disc pl-4'>
                <li>
                  <PLink>Without regard to any choice- or conflict-of-law provisions or rules, the laws of the United States shall govern and govern this agreement.
                  </PLink>
                </li>
                <li>
                  <PLink>Any disputes arising out of or related to this agreement shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.
                  </PLink>
                </li>
              </ul>
              <H3 txt="Miscellaneous:" />
              <ul className='list-disc pl-4'>
                <li>
                  <PLink>ToolsTrain may modify this agreement at any time by posting the revised version on our website. Your continued use of the ToolsTrain Services after the effective date of the revised agreement constitutes your acceptance of the changes.
                  </PLink>
                </li>
                <li>
                  <PLink>If any provision of this agreement is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
                  </PLink>
                </li>
                <li>
                  <PLink>By using the ToolsTrain Services, you acknowledge that you have read, understood, and agreed to be bound by this Agreement.
                  </PLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}