import React, { createContext, useState } from 'react';

const SocialShareContext = createContext();

export const SocialShareProvider = ({ children }) => {
  const [socialicons, setsocialicons] = useState(false);
  const [PostUrl, setPostURl] = useState('');

  const SetAllSocialShareLinks = (post='')=> {
    setPostURl(post);
  }

  const showSocialShare = () => {
    setsocialicons(true);
  };

  const hideSocialShare = () => {
    setsocialicons(false);
  };

  return (
    <SocialShareContext.Provider value={{ socialicons, showSocialShare, hideSocialShare, SetAllSocialShareLinks, PostUrl }}>
      {children}
    </SocialShareContext.Provider>
  );
};

export const useSocialShare = () => {
  return React.useContext(SocialShareContext);
};
