import React from 'react'
import DcumentConversaionSlider from './Category Section/DcumentConversaionSlider'

const CategorySliderFrame = () => {
  return (
    <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
        <div className='grid grid-cols-1 mx-[6%] sm:mx-[5%] '>
            <DcumentConversaionSlider />
        </div>
    </div>
  )
}

export default CategorySliderFrame
