import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { WebServicesContext } from '../context/WebServicesContextState';
import WhyUs from '../sections/WhyUs';
import { Helmet } from 'react-helmet';

const WebServices = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const images = useContext(WebServicesContext);
  return (
    <React.Fragment>
      <Helmet>
        <title>Web Services Toolbox | Make a Web Link Shorter | Tools Train.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Unlock a range of web services with ToolsTrain. From link shortening to image compression, our online tools are designed to optimize your web experience." />
        <meta name="keywords" content="web services, online tools, service tools, utility services" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
      <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
          <div className='grid grid-cols-1 mx-[3%] sm:mx-[5%]'>
          <h3 className='pt-8 font-bold text-lg sm:text-xl'>Web Services Tools</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 py-4">
                  {images.map((item, index) => (
                      <Link key={index} to={item.toPath} className="border-2 border-Secoundaryv1 rounded-xl px-4 py-5 hover:border-Primary">
                          <img src={item.icon} alt={item.alt} className="w-16 h-auto object-cover border-2 border-Secoundary p-1 rounded-md" />
                          <h3 className='line-clamp-2 pt-4 font-semibold'>{item.heading}</h3>
                            <h4 className='line-clamp-3 text-sm sm:text-base my-2'>{item.description}</h4>
                      </Link>
                  ))}
            </div>
          </div>
      </div>
      <WhyUs/>
    </React.Fragment>
  )
}

export default WebServices;
