import React, { useState } from 'react'

export const SearchIcon = ({className}) => {
  const [isHovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(!isHovered);
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
        className={`${className} ${isHovered ? 'hover:' + className : ''}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
    >
        <path d="M9 2C5.1458514 2 2 5.1458514 2 9C2 12.854149 5.1458514 16 9 16C10.747998 16 12.345009 15.348024 13.574219 14.28125L14 14.707031L14 16L20 22L22 20L16 14L14.707031 14L14.28125 13.574219C15.348024 12.345009 16 10.747998 16 9C16 5.1458514 12.854149 2 9 2 z M 9 4C11.773268 4 14 6.2267316 14 9C14 11.773268 11.773268 14 9 14C6.2267316 14 4 11.773268 4 9C4 6.2267316 6.2267316 4 9 4 z"/>
    </svg>
  );
}
