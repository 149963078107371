import React, { useState } from 'react';
import DummyFileUploader from '../../Utilities/DummyProgressBar';

const YoutubeThumbnailDownloaderTool = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [qualities, setQualities] = useState([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [downloadClick, setDownloadClick] = useState(false);
  const [message, setMesage] = useState("");

  const extractVideoId = (url) => {
    // eslint-disable-next-line
    const regularRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const shortRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})/;
    
    const regularMatch = url.match(regularRegex);
    const shortMatch = url.match(shortRegex);
  
    return regularMatch ? regularMatch[1] : (shortMatch ? shortMatch[1] : null);
  };

  const handleInputChange = (e) => {
    setVideoUrl(e.target.value);
    setDownloadClick(false);
    setUploadCompleted(false);
  };

  const handleDownloadClick = async (e) => {
    e.preventDefault();
    try {
      const videoId = extractVideoId(videoUrl);
      if (!videoId) {
        console.error('Invalid YouTube video URL');
        setMesage("Invalid YouTube video URL ! ");
        return;
      }
      setMesage("");
      const thumbnailUrls = [
        `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
        `https://img.youtube.com/vi/${videoId}/sddefault.jpg`,
        `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
      ];
      // All Qualities
      // const thumbnailUrls1 = [
      //   `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
      //   `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
      //   `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`,
      //   `https://img.youtube.com/vi/${videoId}/sddefault.jpg`,
      //   `https://img.youtube.com/vi/${videoId}/default.jpg`,
      //   `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`,
      //   `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`,
      //   `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`,
      //   `https://i.ytimg.com/vi/${videoId}/sddefault.jpg`,
      //   `https://i.ytimg.com/vi/${videoId}/default.jpg`,
      // ];
      setThumbnailUrl(thumbnailUrls);
      setQualities(thumbnailUrls);
      setDownloadClick(true);
    } catch (error) {
      console.error('Error downloading thumbnail:', error);
      setMesage("Invalid URL!"+error);
    }
  };

  const DownloadImage = (quality) =>{
    try{
      if(quality){
        const link = document.createElement('a');
        link.href = quality;
        link.download = ''; 
        window.open(link, '_blank');
      }
      else{
        throw new Error('Quality is empty.');
      }
    }catch(ex){
      throw new Error('Error: ' + ex.message);
    }
  };
  const handleUploadComplete = () => {
    setUploadCompleted(true);
  };
  return (
    <div>
         <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
            <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
                <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                    <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                    YouTube Thumbnail Downloader Online: Grab Attention with Clickworthy Images
                    <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
                </h2>
                <h3 className='text-center text-sm sm:text-lg'>Looking for Youtube Thumbnail Downloader? Use Toolstrain free Youtube thumbnail grabber and get high resolution Images (1080p, 4k, HD) with 3 different sizes!</h3>
            </div>
            <div className="grid grid-cols-[auto_auto] justify-center items-center gap-1 sm:gap-2">
                <label className="grid grid-cols-1 justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White">
                    <input type="url" placeholder="Enter video URL" value={videoUrl} onChange={handleInputChange} className='outline-none'/>
                </label>
                <button
                    className='border-2 border-Primary px-2 sm:px-4 py-2 my-1 mr-1 rounded-md text-White bg-Primary hover:bg-White hover:text-Primary inline-block'
                    onClick={handleDownloadClick}
                >
                    <span className=''>Download</span>
                </button>
            </div>
        </div>
       {message && <p className='text-center mt-8'>{message}</p>}
        {downloadClick ?
        <div className='mt-8'>
          <DummyFileUploader  filename={downloadClick} onUploadComplete={handleUploadComplete}/>
        </div> : ""}
        {thumbnailUrl && uploadCompleted && (
            <div className='mt-4'>
                  {/* {qualities.map((quality, index) => (
                      <div key={index}>
                      <h4>{`${index + 1}. Quality: ${quality}`}</h4>
                      <img src={quality} alt={`Video Thumbnail - Quality ${index + 1}`} />
                      </div>
                  ))}
                  
                  {qualities.map((quality, index) => (
                  <li key={index} className='grid grid-cols-[auto_1fr] items-center'><span>{`${index + 1}.`}</span> <input value={`${quality}`} className='outline-none px-1 py-2 rounded-md mb-2 border-2 border-Primary text-Primary'/></li>
                  ))} */}
                <ul className='grid grid-cols-2 justify-items-end gap-1 sm:gap-2 font-bold'>
                    {qualities.map((quality, index) => (
                        <div key={index} className={index+1===1 ? "col-span-2" : "col-span-1"}>
                          {/* {index+1 ===1 || index+1==6 ? <h4>{`${index + 1}. Quality: ${quality}`}</h4> : ""} */}
                          <h3 className='text-center text-lg py-3'>{`${index + 1}. ${quality.includes("maxresdefault.jpg") ? "Full HD" : quality && quality.includes("sddefault.jpg") ? "Medium" : quality && quality.includes("hqdefault.jpg") ? "Small" : quality}`}</h3>
                          <div className='relative'>
                            <img src={quality} alt={`Video Thumbnail - Quality ${index + 1}`} className='max-w-full' />
                            <div className='absolute top-1 right-1'>
                              <button
                                  className='border-2 border-Primary px-2 sm:px-4 py-2 my-1 mr-1 rounded-md text-White bg-Primary hover:bg-White hover:text-Primary inline-block'
                                  onClick={()=>DownloadImage(quality)}
                              >
                                  <span className=''>Download</span>
                              </button>
                            </div>
                          </div>
                        </div>
                    ))}
                </ul>
            </div>
        )}
    </div>
  );
};

export default YoutubeThumbnailDownloaderTool;
