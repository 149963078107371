import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import BioLinkTool from '../sections/Tool Section/BioLinkTool'
// import CategorySliderFrame from '../sections/CategorySliderFrame'
// import CategorySliderFrame1 from '../sections/CategorySliderFrame1'
import WhyUs from '../sections/WhyUs'
import { Helmet } from 'react-helmet';
import CategorySliderFrame from '../sections/CategorySliderFrame';
import CategorySliderFrame1 from '../sections/CategorySliderFrame1';
import CategorySliderFrame3 from '../sections/CategorySliderFrame3';
import { Banner } from '../Layout/Banner';


const BioLinkToolPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Optimize your bio links for success and maximize your online presence. Drive more traffic and engagement by creating effective and strategic bio links. | ToolsTrain.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Optimize your bio links for success and maximize your online presence. Drive more traffic and engagement by creating effective and strategic bio links." />
        <meta name="keywords" content="bio link tools, custom profile link, personal link creator, profile link shortener, social media bio link" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
        <div className='bg-Transparent1'>
          <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
              <div className=' grid grid-cols-1 mx-[5%] py-[25%] bg-hero-pattern bg-no-repeat bg-center bg-cover opacity-95'>
                <Banner />
              </div>
          </div>
        </div>
        <div className='relative container mx-auto max-w-7xl grid grid-cols-1 mt-4'>
            <div className='grid grid-cols-1 gap-3 mx-[5%] min-h-96 opacity-95'>
              <BioLinkTool />
              <div className='grid grid-cols-1 justify-items-end'>
                <div className='inline-block'>
                  <span className='inline-block mr-4'>Create New Profile --- </span> 
                  <Link to="/create-profile" className='inline-block border-2 border-Primary px-4 py-2 rounded-lg text-Primary'>Create</Link>
                </div>
              </div>
            </div>
        </div>
        <CategorySliderFrame />
        <CategorySliderFrame1 />
        <CategorySliderFrame3 />
        <WhyUs />
    </React.Fragment>
  )
}

export default BioLinkToolPage
