import React, {useState, useRef, useContext} from 'react'
import LeftArrow from '../../assets/icons/prev.svg';
import RightArrow from '../../assets/icons/right_button.svg';
import { Link } from 'react-router-dom';
import { DocConversationContext } from '../../context/DocumentConversionContextState';

const DcumentConversaionSlider = () => {
  const images = useContext(DocConversationContext);
    const sliderRef = useRef(null);
    // eslint-disable-next-line
    const [scrollPosition, setScrollPosition] = useState(0);
  
    const scrollRight = () => {
      if (sliderRef.current) {
        sliderRef.current.scrollBy({
          left: 256, // Adjust this value to control the scroll distance
          behavior: "smooth",
        });
      }
    };
    const scrollLeft = () => {
      if (sliderRef.current) {
        sliderRef.current.scrollBy({
          left: -256, // Adjust this value to control the scroll distance
          behavior: "smooth",
        });
      }
    };

  return (
    <div className='grid grid-cols-1 content-center'>
        <h3 className='pt-8 font-bold text-xl sm:text-2xl'>Document Conversion</h3>
        <div className="relative">
            <div className="grid auto-cols-auto grid-flow-col justify-start gap-2 overflow-auto py-4" ref={sliderRef} onScroll={(e) => setScrollPosition(e.target.scrollLeft)}>
                {images.map((item, index) => (
                    <Link key={index} to={item.ToPath} className="inline-block border-2 border-Secoundaryv1 w-64 rounded-xl px-4 py-5 hover:border-Primary">
                        <img src={item.icon} alt={item.alt} className="w-16 h-auto object-cover border-2 border-Secoundary p-1 rounded-md" />
                        <h3 className='line-clamp-2 pt-4 font-semibold'>{item.focusheading}</h3>
                        <h4 className='line-clamp-3 text-sm sm:text-base my-2'>{item.description}</h4>
                    </Link>
                ))}
                <button className="absolute top-1/2 -left-7 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full" onClick={scrollLeft}>
                   <img src={LeftArrow} alt='Left Arrow' className='w-9 h-auto ' />
                </button>   
                <button className="absolute top-1/2 -right-7 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full" onClick={scrollRight}>
                   <img src={RightArrow} alt='Right Arrow' className='w-9 h-auto ' />
                </button>
            </div>
        </div>
    </div>
  )
}

export default DcumentConversaionSlider;