import React, { useEffect, useState } from 'react';
import deleteIcon from '../../assets/icons/delete_bin.svg'
import { Link } from "react-router-dom";
import { useLoading } from '../../context/LoadingContext';
import LoadingScreen from '../../Utilities/LoadingScreen';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import { SearchIcon } from '../../assets/icons/SearchIcon';

const BioLinkTool = () => {
  const { showLoading, hideLoading } = useLoading();
  const [profiles, setProfiles] = useState([]);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);

  useEffect(() => {
    if (search.trim() === '') {
      setFilteredPosts(profiles);
    } else {
      const filtered = profiles.filter((post) =>
        post.username.toLowerCase().includes(search.toLowerCase()) || post.description.toLowerCase().includes(search.toLowerCase()) || post.websiteLink.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredPosts(filtered);
    }
  }, [search, profiles]);

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        showLoading();
        const response = await fetch('https://toolstrain.xyz/Bio');
        if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
        const data = await response.json();
        setProfiles(data);
        console.log(profiles);
      } catch (error) {
        console.error('Error fetching profiles:', error.message);
        setError('An error occurred while fetching profiles.');
      }
      finally{hideLoading();}
    };
    fetchProfiles();
    // eslint-disable-next-line
  }, []); 
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const HandelSearchBlog = () => {
    setSearchClicked(!searchClicked);
    if(searchClicked){setSearch('');}
  }
  return (
    <React.Fragment>
      <div className='grid grid-cols-1 justify-items-end items-end'>
      {searchClicked? 
        <div className='grid grid-cols-[1fr_auto] gap-2'>
          <input className='w-32 sm:w-full text-Black outline-none border-b-2 border-Primary px-2' placeholder='Search ...' value={search} onChange={(e) => setSearch(e.target.value)} />
          <div className='border border-Primary hover:bg-Primary rounded-lg' onClick={HandelSearchBlog}>
            <CloseIcon className="fill-Primary hover:fill-White w-full h-auto max-w-[28px] p-1"/>
          </div>
        </div>: 
        <div className='border border-Primary hover:bg-Primary rounded-lg' onClick={HandelSearchBlog}>
          <SearchIcon className="fill-Primary hover:fill-White w-full h-auto max-w-[28px] p-1"/>
        </div>
      }
      </div>
      <div className='grid grid-cols-1 max-h-[500px] overflow-auto'>
        {error && <p>{error}</p>}
        {filteredPosts.length > 0 ? (
          <table className="min-w-full border-2 border-Transparent1 border-collapse">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider whitespace-nowrap">Profile URL</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Username</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Description</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Instagram</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">TikTok</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Facebook</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Youtube</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider whitespace-nowrap">WhatsApp Business</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider whitespace-nowrap">Only Fans</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Portfolio</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Website</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Amazon</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">LinkedIn</th>
                <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider whitespace-nowrap">Action</th>
                {/* Add similar classes for other columns */}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredPosts && filteredPosts.map((profile, index) => (
                <tr key={index} className={index % 2 === 1 ? 'bg-Primary text-White' : 'bg-White'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <Link to={profile.id && profile.id !=="string" ? "https://toolstrain.com/profile/"+profile.id : "-"} className='hover:underline' target='_blank'> Profile </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.username && profile.username !=="string" ? profile.username : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.description && profile.description !=="string" ? profile.description : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.instagram && profile.instagram !=="string" ? profile.instagram : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.tikTok && profile.tikTok !=="string" ? profile.tikTok : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.facebook && profile.facebook !=="string" ? profile.facebook : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.youtube && profile.youtube !=="string" ? profile.youtube : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm hover:underline">{profile.whatsAppBusiness && profile.whatsAppBusiness !=="string" ? <Link className='hover:cursor-pointer' target='_blank' to={`https://wa.me/${profile.whatsAppBusiness}`}>{profile.whatsAppBusiness}</Link> : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.onlyFans && profile.onlyFans !=="string" ? profile.onlyFans : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.portfolioLink && profile.portfolioLink !=="string" ? profile.portfolioLink : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.websiteLink && profile.websiteLink !=="string" ? profile.websiteLink : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.amazonLink && profile.amazonLink !=="string" ? profile.amazonLink : "-"}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{profile.linkedInLink && profile.linkedInLink !=="string" ? profile.linkedInLink : "-"}</td>
                  <td className="grid grid-cols-[auto_auto] justify-items-center justify-center items-center mt-[10px] w-52">
                    <Link to={"/edit-profile/"+profile.id} className='text-sm whitespace-nowrap underline hover:decoration-debug' >
                      Already Registered
                      {/* <img className='w-8' alt='Edit Icon' src={editIcon} /> */}
                    </Link>
                    <Link to={"/delete-bio-profile/"+profile.id} className='hover:shadow-lg '>
                      <img className='w-8' alt='Delete Icon' src={deleteIcon}/>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : 
          <>
            {<LoadingScreen />}
          </>
        }
      </div>
    </React.Fragment>
  )
}
export default BioLinkTool


//   // Memoize the component rendering
//   const memoizedComponent = useMemo(() => (

//   ), [profiles, error]); // Only recompute the memoized component if profiles or error change

//   return memoizedComponent;
// });