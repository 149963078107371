import React from 'react'

export const Banner = () => {
  return (
    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
        <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
            <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                Optimize Your Bio Links for Success 
                <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
            </h2>
            <h3 className='text-center text-sm sm:text-lg'>Optimize your bio links for success and maximize your online presence. Drive more traffic and engagement by creating effective and strategic bio links.</h3>
        </div>
    </div>
  )
}
