import PaharaphrasesIcon from '../assets/icons/summary_list.svg';
import {createContext} from 'react';

export const TextAssistanceContext = createContext();

const TextAssistanceContextState = (props) => {
    const TextAssistanceTools = [
        {
          icon: PaharaphrasesIcon,
          alt: 'Text Paragraph Summarizer - AI Tool Toolstrain.com',
          heading: 'Rephases Tool',
          description: 'Summarize your given text with in a minute.',
        }
    ];
    return (
        <TextAssistanceContext.Provider value={TextAssistanceTools}>
            {props.children}
        </TextAssistanceContext.Provider>
    )
}
export default TextAssistanceContextState;