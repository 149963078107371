import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../Utilities/LoadingScreen';
import { useLoading } from '../context/LoadingContext';
import { Helmet } from 'react-helmet';

const CreateBioProfile = () => {
    const {showLoading, hideLoading } = useLoading();
    const [submited, setSubmited] = useState(false);
    const navigate = useNavigate();
    const [Message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        username: '',
        description: '',
        instagram: '',
        WebsiteLink: '',
        tiktok:'',
        facebook:'',
        Youtube:'',
        whatsappbusiness:'',
        portfoliolink:'',
        AmazonLink:'',
        OnlyFans:'',
        LinkedInLink:'',
        password:'',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
    };
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            showLoading();
            setSubmited(true);
          const response = await fetch('https://toolstrain.xyz/Bio', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          setMessage("Profile created successfully!");
          // Handle success, maybe show a success message or redirect
          console.log('Profile created successfully!');
          navigate("/bio");
        } catch (error) {
          console.error('Error creating profile:', error.message);
          setMessage('User name Already exist, Error creating profile: '+ error.message);
        }
        finally{
            hideLoading();
            setSubmited(false);
        }
    };

  return (
    <React.Fragment>
        <Helmet>
            <title>Create a Profile at ToolsTrain | Personalized Experience.</title>
            <meta charset="utf-8" />
            <meta name="description" content="Enhance your experience by creating a profile at ToolsTrain. Enjoy personalized features and access to a suite of free online tools." />
            <meta name="keywords" content="create profile, user account creation, account setup, registration page, profile management" />
            <link rel="canonical" href={window.location.href} />
            {/* Add more meta tags or head elements as needed */}
        </Helmet>
        <div className='relative container mx-auto max-w-7xl grid grid-cols-1'>
            <div className='grid grid-cols-1 gap-3 mx-[5%] min-h-96 opacity-95'>
                {!submited ? <div className='border-2 border-Primary'>
                    {Message === "Access Granted!" ? <p className='text-center text-Primary py-4'>{Message} - Welcom {formData.username}</p> : ""}
                    <form onSubmit={handleSubmit}  className='grid grid-cols-1 sm:grid-cols-2 max-w-7xl mx-auto  px-2 py-4 rounded-md gap-4 items-end'>
                        <label>Username: <br />
                            <input className='border-b-2 border-b-Black focus:border-b-Primary outline-none  px-4 py-2 w-full bg-All-Primary' type="text" name="username" value={formData.username} onChange={handleChange}/>
                        </label>
                        <label>Description: <br />
                            <textarea name="description" className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' value={formData.description} onChange={handleChange} />
                        </label>

                        <label>Instagram: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="url" name="instagram" value={formData.instagram} onChange={handleChange} />
                        </label>
                        <label>TikTok: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="url" name="tiktok" value={formData.tiktok} onChange={handleChange} />
                        </label>
                        <label>Facebook: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="url" name="facebook" value={formData.facebook} onChange={handleChange} />
                        </label>
                        <label>Youtube: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="url" name="Youtube" value={formData.Youtube} onChange={handleChange} />
                        </label>
                        <label>Whatsapp Business: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="whatsappbusiness" value={formData.whatsappbusiness} onChange={handleChange} />
                        </label>
                        <label>Only Fans: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="url" name="OnlyFans" value={formData.OnlyFans} onChange={handleChange} />
                        </label>

                        <label>Portfolio: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="url" name="portfoliolink" value={formData.portfoliolink} onChange={handleChange} />
                        </label>
                        <label>Website: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="url" name="WebsiteLink" value={formData.WebsiteLink} onChange={handleChange} />
                        </label>                    
                        <label>Amazon: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="url" name="AmazonLink" value={formData.AmazonLink} onChange={handleChange} />
                        </label>
                        <label>Linkedin: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="url" name="LinkedInLink" value={formData.LinkedInLink} onChange={handleChange} />
                        </label>
                        
                        <label>Password: <br />
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" name="password" value={formData.password} onChange={handleChange} minlength="8" />
                        </label>
                        <button type='submit' className='border-2 border-Primary rounded-md px-4 py-2 hover:bg-Primary hover:text-White'>Submit</button>
                    </form>
                    {Message}
                </div> : <LoadingScreen />}
            </div>
        </div>
    </React.Fragment>
  )
}

export default CreateBioProfile
