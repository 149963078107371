import React, { useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import deleteIcon from '../../assets/icons/delete_bin.svg'
import currencyPath from '../../context/Currencies.json'

const InvoiceGeneratorTool = () => {
  const [invoiceNo, setInvoiceNo] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('');
  const [date, setDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [poNumber, setPoNumber] = useState('');
  const [billTo, setBillTo] = useState('');
  const [shipTo, setShipTo] = useState('');
  const [from, setFrom] = useState('');
  const [logo, setLogo] = useState(null);
  const [currency, setCurrency] = useState('$');
  const [items, setItems] = useState([{ description: '', qty: 0, rate: 0 }]);
  const componentRef = React.useRef();
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    setCurrencies(currencyPath);
  }, []);

  const addNewItem = () => {
    setItems([...items, { description: '', qty: 0, rate: 0 }]);
  };

  const deleteItem = (index) => {
    if (items.length > 1) {
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
    }
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };

  const calculateAmount = (item) => {
    return item.qty * item.rate;
  };

  const calculateSubtotal = () => {
    return items.reduce((total, item) => total + calculateAmount(item), 0);
  };

  const calculateTotal = () => {
    // Add any additional calculations based on your needs
    return calculateSubtotal();
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setLogo(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setLogo(null);
    }
  };

  const generatePDF = () => {
    if (logo) {
      const content = componentRef.current;
      const pdfOptions = { margin: 0, filename: 'image.pdf'};
      html2pdf(content, pdfOptions);
    }
  };

  return (
    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
        <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
            <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                Online Free Invoice Generator: Manage Finances Seamlessly
                <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
            </h2>
            <h3 className='text-center text-sm sm:text-lg'>Manage your finances seamlessly with our online free invoice generator. Create professional invoices hassle-free.</h3>
        </div>
        <div className="grid grid-cols-1 gap-2">

          <div className='grid grid-cols-1 sm:grid-cols-[auto_auto] items-start justify-between'>
            
            <div className='grid grid-cols-1 gap-2 justify-self-center sm:justify-self-start mb-4 sm:mb-0'>
              <label className="grid grid-cols-[auto_auto] gap-2 border-2 border-Primary p-2 rounded-lg bg-White justify-self-start noPrint print:hidden">
                <svg className="w-8 h-8" fill="#429FC5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal text-Primary">Select Logo</span>
                <input type='file' className="hidden"  id="logo" onChange={handleLogoChange} accept="image/*" />
              </label>
              {logo && (
                <img src={logo} alt="Selected" className="w-1/3 rounded-lg" />
              )}
            </div>

            <div className='grid grid-cols-1 gap-2 mt-0 sm:mt-0'>
              <div className='grid grid-cols-[auto_1fr] items-center bg-White border-b-4 border-Primary focus:border-Secoundaryv1 rounded-tl-lg rounded-tr-lg'>
                <div className='border-2 border-Primary px-2 rounded-full ml-2'>#</div>
                <input
                  type="text"
                  id="invoiceNo"
                  placeholder='Invoice Number'
                  value={invoiceNo}
                  className='outline-none p-2 w-full'
                  onChange={(e) => setInvoiceNo(e.target.value)}
                />
              </div>
              <div>
                <select
                  id="currency"
                  value={currency}
                  className='grid grid-cols-1 gap-2 w-full max-w-xs overflow-hidden outline-none p-2'
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option className='w-full' value="$">Select a currency</option>
                  {currencies.map(currency => (
                    <option className='w-full px-4 py-2' key={currency.Currency_Symbol} value={currency.Currency_Symbol}>
                      {currency.Currency_Name} ({currency.Currency_Symbol})
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 sm:grid-cols-[1fr_auto] gap-2'>
            <div className='grid grid-cols-1 gap-2'>
              <div className='grid grid-cols-1 max-w-xl'>
                <textarea
                  placeholder='Who is this invoice From'
                  id="from"
                  className='outline-none p-2 border-2 border-Primary rounded-lg max-w-xl'
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  rows={5}>
                </textarea>
              </div>
              <div className='grid grid-cols-2 max-w-xl gap-2 mt-4'>
                <div className='grid grid-cols-1'>
                  <label htmlFor="billTo" className='font-bold text-base'>BILL TO:</label>
                  <textarea 
                    id="billTo"
                    value={billTo}
                    placeholder='London to New York'
                    className='outline-none p-2 border-2 border-Primary rounded-lg'
                    onChange={(e) => setBillTo(e.target.value)}
                    rows={3}>
                  </textarea>
                </div>
                <div className='grid grid-cols-1'>
                  <label htmlFor="shipTo" className='font-bold text-base'>SHIP TO:</label>
                  <textarea 
                    id="shipTo"
                    value={shipTo}
                    placeholder='London to New York'
                    className='outline-none p-2 border-2 border-Primary rounded-lg'
                    onChange={(e) => setShipTo(e.target.value)}
                    rows={3}>
                  </textarea>
                </div>
              </div>
            </div>

            <div className='grid grid-cols-1 gap-4'>
              <div className='grid grid-cols-1'>
                <label htmlFor="date" className='font-bold text-base'>DATE:</label>
                <input
                  type="date"
                  id="date"
                  value={date}
                  className='p-2 outline-none rounded-md'
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className='grid grid-cols-1'>
                <input
                  type="text"
                  id="paymentTerms"
                  placeholder='Payment Terms'
                  value={paymentTerms}
                  className='outline-none p-2 border-b-4 border-Primary focus:border-Secoundaryv1 rounded-tl-lg rounded-tr-lg'
                  onChange={(e) => setPaymentTerms(e.target.value)}
                />
              </div>
              <div className='grid grid-cols-1'>
                <label htmlFor="dueDate" className='font-bold text-base'>DUE DATE:</label>
                <input
                  type="date"
                  id="dueDate"
                  value={dueDate}
                  className='p-2 outline-none rounded-md'
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </div>
              <div className='grid grid-cols-1'>
                <input
                  type="text"
                  id="poNumber"
                  placeholder='PO Number'
                  value={poNumber}
                  className='outline-none p-2 border-b-4 border-Primary focus:border-Secoundaryv1 rounded-tl-lg rounded-tr-lg'
                  onChange={(e) => setPoNumber(e.target.value)}
                />
              </div>
              <div className='grid grid-cols-1 bg-Primary px-2 py-3 rounded-md text-White'>
                Balance Due: {`${currency} ${calculateTotal()}`}
              </div>
            </div>
          </div>

          <table className='grid grid-cols-1 mt-7 overflow-auto max-w-full'>
            <thead className='grid grid-cols-1 bg-Primary text-White rounded-md'>
              <tr className='grid grid-cols-3 sm:grid-cols-5 border-2 border-White rounded-md'>
                <th className='whitespace-nowrap px-1 py-3'>Description</th>
                <th className='whitespace-nowrap px-1 py-3'>Quantity</th>
                <th className='whitespace-nowrap px-1 py-3'>Rate</th>
                <th className='whitespace-nowrap px-2 py-3 hidden sm:block'>Amount ({currency})</th>
                <th className='whitespace-nowrap px-2 py-3 hidden sm:block'>Action</th>
              </tr>
            </thead>
            <tbody className='grid grid-cols-1 gap-2'>
              {items.map((item, index) => (
                <tr key={index} className='grid grid-cols-1 sm:grid-cols-5 gap-3 mt-4'>
                  <td>
                    <input
                      type="text"
                      value={item.description}
                      placeholder='Description ...'
                      className='outline-none p-2 border-b-4 border-Primary focus:border-Secoundaryv1 rounded-tl-lg rounded-tr-lg w-full'
                      onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={item.qty}
                      className='outline-none p-2 border-b-4 border-Primary focus:border-Secoundaryv1 rounded-tl-lg rounded-tr-lg w-full'
                      onChange={(e) => handleItemChange(index, 'qty', parseFloat(e.target.value))}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={item.rate}
                      className='outline-none p-2 border-b-4 border-Primary focus:border-Secoundaryv1 rounded-tl-lg rounded-tr-lg w-full'
                      onChange={(e) => handleItemChange(index, 'rate', parseFloat(e.target.value))}
                    />
                  </td>
                  <td className='grid grid-cols-1 justify-items-center items-center'>
                    <div className='bg-Primary rounded-md text-White p-2 text-center'>
                    {`${currency} ${calculateAmount(item)}`}
                    </div>
                  </td>
                  <td className='print:hidden grid grid-cols-1 justify-items-center items-center'>
                    {items.length > 1 && (
                      <img className='w-8' alt='Delete Icon' src={deleteIcon} onClick={() => deleteItem(index)}/>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='grid grid-cols-1 mt-4'>
            <button onClick={addNewItem} className='bg-Primary p-2 border-2 border-White text-White rounded-lg justify-self-center'>Add Item</button>
            <div className='grid grid-cols-1 ml-auto mt-3 gap-3'>
              <p className='font-bold text-base italic'>Subtotal:{`${currency} ${calculateSubtotal()}`}</p>
              <p className='font-bold text-base italic'>Total: {`${currency} ${calculateTotal()}`}</p>
            </div>
            <button onClick={generatePDF} className='mt-4 bg-Primary p-2 border-2 border-White text-White rounded-lg'>Download Invoice</button>
          </div>
        </div>
        <div className='w-full overflow-auto hidden'>
          <div id='ActualPrintPreview' className='grid grid-cols-1 gap-2 p-4 border-2 m-4 border-Primary h-full bg-White max-w-full' ref={componentRef}>
            <div className='grid grid-cols-[auto_auto] items-start justify-between'>
              
              <div className='grid grid-cols-1 gap-2'>
                {logo && (
                  <img src={logo} alt="Selected" className="w-1/3 rounded-lg" />
                )}
              </div>

              <div className='grid grid-cols-1 gap-2'>
                <div className='grid grid-cols-1 gap-4'>
                  <p className='text-4xl'>INVOICE</p>
                  <p className='text-right'>
                    #{invoiceNo}
                  </p>
                  <p className='text-right'>
                    {date}
                  </p>
                </div>
              </div>
            </div>

            <div className='grid grid-cols-[1fr_auto] content-center'>
              <div className='grid grid-cols-1 gap-2'>
                <div className='grid grid-cols-1 max-w-xl'>
                  <pre className='font-bold text-base italic pt-4'>
                    {from}
                  </pre>
                </div>
                <div className='grid grid-cols-2 max-w-xl gap-2'>
                  <div className='grid grid-cols-1'>
                    <label htmlFor="billTo" className='font-bold text-base italic'>BILL TO:</label>
                    <pre>
                      {billTo}
                    </pre>
                  </div>
                  <div className='grid grid-cols-1'>
                    <label htmlFor="shipTo" className='font-bold text-base italic'>SHIP TO:</label>
                    <pre>
                      {shipTo}
                    </pre>
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-1 gap-2'>
                <div className='grid grid-cols-1'>
                  <label className='font-bold text-base italic'>Currency: {currency}</label>
                </div>
                <div className='grid grid-cols-1'>
                  <label className='font-bold text-base italic'>Payment Terms: {paymentTerms}</label>
                </div>
                <div className='grid grid-cols-1'>
                  <label htmlFor="dueDate" className='font-bold text-base italic'>Due Date: {dueDate}</label>
                </div>
                <div className='grid grid-cols-1'>
                  <label htmlFor="dueDate" className='font-bold text-base italic'>PO Number: {poNumber}</label>
                
                </div>
                <div className='grid grid-cols-1 border-b-2 border-Primary font-bold text-base italic text-Primary px-2 py-3 rounded-md'>
                  Balance Due: {`${currency} ${calculateTotal()}`}
                </div>
              </div>
            </div>

            <table className='grid grid-cols-1 mt-7'>
              <thead className='grid grid-cols-1 border-t-2 border-b-2 border-Primary text-Primary rounded-md'>
                <tr className='grid grid-cols-4 items-center gap-3 rounded-md h-auto pb-5'>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Amount ({currency})</th>
                </tr>
              </thead>
              <tbody className='grid grid-cols-1 gap-2'>
                {items.map((item, index) => (
                  <tr key={index} className='grid grid-cols-4 gap-3 mt-4'>
                    <td className=''>
                    {item.description}
                    </td>
                    <td className='grid grid-cols-1 justify-items-center items-center'>
                      {item.qty}
                    </td>
                    <td className='grid grid-cols-1 justify-items-center items-center'>
                      {item.rate}
                    </td>
                    <td className='grid grid-cols-1 justify-items-center items-center'>
                      {`${currency} ${calculateAmount(item)}`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className='grid grid-cols-1 ml-auto gap-3 text-center mt-4'>
              <p className='font-bold text-base italic'>Subtotal:{`${currency} ${calculateSubtotal()}`}</p>
              <p className='font-bold text-base italic'>Total: {`${currency} ${calculateTotal()}`}</p>
            </div>
          </div>
        </div>
    </div>
  );
};

export default InvoiceGeneratorTool;