import React from 'react'
import ProductivityToolsSlider from './Category Section/ProductivityToolsSlider'

const CategorySliderFrame3 = () => {
  return (
    <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
        <div className='grid grid-cols-1 mx-[6%] sm:mx-[5%] '>
          <ProductivityToolsSlider />
        </div>
    </div>
  )
}

export default CategorySliderFrame3
