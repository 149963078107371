import React, { useState, useEffect } from 'react';

const DummyProgressBar = ({ progress }) => {
  const progressBarStyle = {
    width: `${progress}%`,
    height: '30px',
    backgroundColor: '#429FC5',
    borderRadius: '5px',
    transition: 'width 0.3s ease',
  };

  return (
    <div>
      <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflow: 'hidden' }}>
        <div style={progressBarStyle}></div>
      </div>
      <p style={{ marginTop: '10px' }}>Progress: {progress}%</p>
    </div>
  );
};

const DummyFileUploader = ({ filename, onUploadComplete }) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
     // Reset progress when a new file is selected
     setUploadProgress(0);
    if (filename) {
      simulateFileUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filename]);

  const simulateFileUpload = () => {
    // Simulating file upload progress
    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        const newProgress = prevProgress + 10;
        if (newProgress >= 100) {
          clearInterval(interval);
          onUploadComplete(); // Trigger the callback when upload is complete
          return 100; // Ensure progress does not exceed 100%
        }
        return newProgress;
      });
    }, 500);
  };

  return (
    <div>
      <DummyProgressBar progress={uploadProgress} />
    </div>
  );
};

export default DummyFileUploader;