import React, { useState, useEffect } from 'react';
import LoadingScreen from '../Utilities/LoadingScreen';
import { Link, useParams } from 'react-router-dom';
// Improvement when click on phone no redirect to whatsapp business no
// Backend: On Edit Case user can have access to add heading ("Intagram") and also can set value like ("https://www.instagram.com/@username")

const Profile = () => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const { id } = useParams();
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    useEffect(() => {
      let isMounted = true;
        const fetchProfile = async () => {
          try {
            const response = await fetch(`https://toolstrain.xyz/Bio/${id}`);
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            if (isMounted) {
              setProfile(data);
            }
          } catch (error) {
            setError(error.message);
          } finally {
            if (isMounted) {
              setLoading(false);
            }
          }
        };
    
        fetchProfile();
      }, [id,]);
      
  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <div>Error : {error}</div>;
  }

  if (!profile) {
    return <div>No profile found</div>;
  }  

  return (
    <React.Fragment>
      <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
            <div className='grid grid-cols-1 mx-[5%] border-2 border-Primary rounded-lg py-6 px-2 gap-3'>
              <p className='text-center hover:text-Primary text-lg sm:text-2xl md:text-3xl font-semibold'>
                <Link to={"https://toolstrain.com/profile/"+profile.id} >{profile.username}</Link>
              </p>
              <p className='text-center text-sm max-w-xs mx-auto py-1 font-semibold'>{profile.description}</p>
              {(profile.instagram || profile.tikTok || profile.facebook || profile.youtube || profile.whatsAppBusiness || profile.onlyFans) ? <div className='w-full sm:min-w-[300px] sm:max-w-[300px] mx-auto grid gap-3'>
                <h2 className='font-semibold underline text-Primary'>Social Media</h2>
                <div className='grid grid-cols-1 gap-2'>
                  {profile.instagram && <Link to={profile.instagram} target='_blank' className='text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary hover:underline'>Instagram</Link>}
                  {profile.tikTok && <Link to={profile.tikTok} target='_blank' className='text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary hover:underline'>TikTok</Link>}
                  {profile.facebook && <Link to={profile.facebook} target='_blank' className='text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary hover:underline'>Facebook</Link>}
                  {profile.youtube && <Link to={profile.youtube} target='_blank' className='text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary hover:underline'>Youtube</Link>}
                  {profile.whatsAppBusiness && <Link className='hover:cursor-pointer hover:underline text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary overflow-auto' target='_blank' to={`https://wa.me/${profile.whatsAppBusiness}`}>Whatsapp Business {profile.whatsAppBusiness}</Link>}
                  {profile.onlyFans && <Link to={profile.onlyFans} target='_blank' className='text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary hover:underline'>Only Fans</Link>}
                </div>
              </div> : <p className='text-center font-semibold underline text-Primary'>No Social Detail Found!</p>}
              {(profile.portfolioLink || profile.websiteLink || profile.amazonLink || profile.linkedInLink) ? <div className='w-full sm:min-w-[300px] sm:max-w-[300px] mx-auto grid gap-3'>
                <h2 className='font-semibold underline text-Primary'>Business</h2>
                <div className='grid grid-cols-1 gap-2'>
                  {profile.portfolioLink && <Link to={profile.portfolioLink} target='_blank' className='text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary hover:underline'>Portfolio</Link>}
                  {profile.websiteLink && <Link to={profile.websiteLink} target='_blank' className='text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary hover:underline'>Website</Link>}
                  {profile.amazonLink && <Link to={profile.amazonLink} target='_blank' className='text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary hover:underline'>Amazon</Link>}
                  {profile.linkedInLink && <Link to={profile.linkedInLink} target='_blank' className='text-center bg-Loading px-2 py-3 text-White rounded-md hover:bg-Primary hover:underline'>linkedIn</Link>}
                </div>
              </div> : <p className='text-center font-semibold underline text-Primary'>No Business Detail Found!</p>}
            </div>
      </div>
    </React.Fragment>
  );
}

export default Profile
