import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
// Also make tis services heading as a link to download Price packedges file
export const Api = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const handleDownload = () => {
    fetch('https://docs.google.com/document/d/1DlEA1LQwo2Uhu4H-_49Aw3Pp7DBH7bkgsfErH8tlgAc/export?format=docx')
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'TOOLS TRAIN PACKAGES.docx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => console.error('Error downloading file:', error));
  };
  const redirectToWhatsApp = () => {
    const phoneNumber = '+923020008853';
    const message = encodeURIComponent('May I assist you in selecting the service that best fits your needs?');
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappURL, '_blank');
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Toolstrain API services - Integrate Our Tools Into Your Apps | Toolstrain.com</title>
        <meta charset="utf-8" />
        <meta name="description" content="Easily access Toolstrain API tools programmatically via our API. Docs, examples, and support available to get you started quickly." />
        <meta name="keywords" content="" />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "https://toolstrain.com/api"
                },
                "headline": "Toolstrain API services - Integrate Our Tools Into Your Apps",
                "image": "",  
                "author": {
                  "@type": "Organization",
                  "name": "toolstrain",
                  "url": "https://toolstrain.com/"
                },  
                "publisher": {
                  "@type": "Organization",
                  "name": "toolstrain",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://toolstrain.com/"
                  }
                },
                "datePublished": "2024-07-22",
                "dateModified": "2024-07-22"
          }`}
        </script>
        <meta property="og:title" content="Toolstrain API services - Integrate Our Tools Into Your Apps" />
        <meta property="og:site_name" content="Toolstrain" />
        <meta property="og:url" content="https://toolstrain.com/api" />
        <meta property="og:description" content="Easily access Toolstrain API tools programmatically via our API. Docs, examples, and support available to get you started quickly." />
        <meta property="og:type" content="" />
        <meta property="og:image" content="" />
        <meta name="twitter:card" content="app" />
        <meta name="twitter:site" content="@toolstrain" />
        <meta name="twitter:description" content="Easily access Toolstrain API tools programmatically via our API. Docs, examples, and support available to get you started quickly." />
        <meta name="twitter:app:name:iphone" content="toolstrain" />
        <meta name="twitter:app:id:iphone" content="" />
        <meta name="twitter:app:name:ipad" content="toolstrain" />
        <meta name="twitter:app:id:ipad" content="" />
        <meta name="twitter:app:name:googleplay" content="toolstrain" />
        <meta name="twitter:app:id:googleplay" content="" />
        <meta name="twitter:app:country" content="" />
      </Helmet>
      <div className='relative container mx-auto max-w-7xl grid grid-cols-1'>
        <div className='grid grid-cols-1 mx-[5%] mt-8 py-4'>
          <div className="bg-gray-100 h-auto flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-md w-full">
              <h2 className="text-2xl font-semibold mb-4 text-center">API - SERVICES</h2>
              <p className="text-gray-700">
                Toolstrain offers a versatile API granting access to a diverse array of practical tools catering to various needs. From generating invoices to compressing images, shortening URLs, creating QR codes, and even scraping data, our API empowers developers to integrate these functionalities seamlessly into their applications. Our developmenet team will create the exexct and reliable endpoint of api according to your needs. Below are the plan details:
              </p>
              <div class="relative overflow-x-auto shadow-md sm:rounded-lg my-4">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead class="text-xs text-White bg-Primary uppercase border-b-2 border-White">
                    <tr className='text-center'>
                      <th scope="col" class="text-base px-6 py-4 font-semibold border-r-2 border-r-White">
                        SERVICES
                      </th>
                      <th scope="col" class="text-base px-6 py-4 font-semibold">
                        Free Trial
                      </th>
                      <th scope="col" class="text-base px-6 py-4 font-semibold">
                        Micro
                      </th>
                      <th scope="col" class="text-base px-6 py-4 font-semibold">
                        Advanced
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b">
                      {/* https://oxylabs.io/products/scraper-api/web/pricing */}
                      <th scope="row" class="px-6 py-4 font-semibold text-Black whitespace-nowrap border-r-2 border-r-Primary ">
                        1. Data Scraping Solution
                      </th>
                      <td class="px-6 py-4 text-center">
                        Free Trail
                      </td>
                      <td class="px-6 py-4 text-center">
                        <p>$17/month</p>
                      </td>
                      <td class="px-6 py-4 text-center">
                        <p>$20/month</p>
                      </td>
                    </tr>
                    <tr class="border-b bg-Primary text-White">
                      {/* https://postmarkapp.com/pricing, https://postmarkapp.com/blog/the-best-smtp-email-services-comparison-sheet#1-postmark */}
                      <th scope="row" class="px-6 py-4 font-semibold whitespace-nowrap border-r-2 border-r-White">
                        2. Mail Integration Services
                      </th>
                      <td class="px-6 py-4 text-center">
                        Free Trail
                      </td>
                      <td class="px-6 py-4 text-center">
                        <p>$15/month</p>
                      </td>
                      <td class="px-6 py-4 text-center">
                        <p>$18/month</p>
                      </td>
                    </tr>
                    {/* 
                          <tr class="border-b">
                              <th scope="row" class="px-6 py-4 font-semibold whitespace-nowrap border-r-2 border-r-Primary">
                                3. Request for service
                              </th>
                              <td class="px-6 py-4 text-center">
                              Free Trail
                              </td>
                              <td class="px-6 py-4 text-center">
                                <p>-/month</p>
                              </td>
                              <td class="px-6 py-4 text-center">
                                <p>-/month</p>
                              </td>
                          </tr>
                        */}
                  </tbody>
                </table>
              </div>
              <p className='text-xs text-center my-6'>Our support team will help developers to develop custom endpoints as well. Feel free to <button onClick={redirectToWhatsApp} className='border-b-2 border-Primary hover:border-White'>Order Now</button></p>
              <div className='grid grid-cols-1 items-center justify-items-center'>
                <button onClick={handleDownload} className="bg-Primary text-White hover:bg-White hover:text-Primary hover:border-Primary border rounded-md py-2 px-6 text-base sm:text-lg">OUR PACKAGES</button>
              </div>
              {/* <p className='text-xs text-center my-6'>By using these tools, you agree to the terms and conditions outlined in our Privacy Policy.</p> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
// <li>We do not guarantee the accuracy, reliability, or completeness of the generated content.</li>
//             // bg-Primary text-White' : 'bg-White


// <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Website</th>
// <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Plan</th>
// <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Start</th>
// <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Expiry</th>
// <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Payment</th>
// <th className="px-6 py-3 text-left text-sm sm:text-base font-semibold text-White bg-Primary uppercase tracking-wider">Developer Contact</th>

// <td className="px-6 py-4 whitespace-nowrap text-sm">https://theclinicchecker.com/</td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm">Trail</td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm">04/25/2024</td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm">04/27/2024</td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm">-</td>
//                     <td className="px-6 py-4 whitespace-nowrap text-sm hover:underline">Whatsapp Contact</td>