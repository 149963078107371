import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>ToolsTrain Terms and Conditions | Usage Guidelines.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Explore the terms and conditions of ToolsTrain. Read our guidelines for using our online tools to ensure a smooth and secure experience." />
        <meta name="keywords" content="terms and conditions, user agreement, website terms, service terms, terms of service" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
      <div className='relative container mx-auto max-w-7xl grid grid-cols-1'>
        <div className='grid grid-cols-1 mx-[5%] mt-8 py-4'>
          <div className="bg-gray-100 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-md w-full">
              <h2 className="text-2xl font-semibold mb-4">Terms and Conditions</h2>
              <p className="text-gray-700">
                Please read these terms and conditions carefully before using our online tools.
              </p>
              <ul className="list-disc pl-4 mt-4">
                <li>By using our services, you agree to comply with these terms and conditions.</li>
                <li>We reserve the right to modify or terminate our services at any time.</li>
                <li>Your use of our services is at your own risk.</li>
                <li>... (Add more terms and conditions as needed)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TermsAndConditions
