import React, { useContext, useEffect, useState } from 'react'
import WhyUs from '../sections/WhyUs'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { BlogsContext } from '../context/BlogsContextState';
import { useSocialShare } from '../context/SocialShareContext';
import { SocialSharePopup } from '../Utilities/SocialSharePopup';
import { ShareIcon } from '../assets/icons/ShareIcon';
import { SearchIcon } from '../assets/icons/SearchIcon';
import { CloseIcon } from '../assets/icons/CloseIcon';
import PopularBlogThumbnail from '../assets/images/Youtube Thumbnail Downloader.jpeg';

const Blog = () => {
  const {showSocialShare, hideSocialShare, socialicons, SetAllSocialShareLinks} = useSocialShare();
  const [search, setSearch] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);
  const Blogs = useContext(BlogsContext);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const currentHostname = window.location.protocol + "//" + window.location.hostname;
  const DateIntoYMD=(fromdate)=>{
    const birthDateObj = new Date(fromdate);
    const currentDate = new Date();

    if (isNaN(birthDateObj.getTime())) {
      throw new Error('Invalid date. Please enter a valid date.');
    }

    if ( birthDateObj>=currentDate) {
      throw new Error('Please enter older date with respect to current date.');
    }

    const ageDiff = currentDate - birthDateObj;
    const ageDate = new Date(ageDiff);

    const years = Math.abs(ageDate.getUTCFullYear() - 1970);
    const months = ageDate.getUTCMonth();
    const days = ageDate.getUTCDate() - 1; // Subtracting 1 to adjust for day of birth

    if(years!==0){
      return years+"Y "+months + "M " +days + "D ago";
    }
    else if(months!==0){
      return months + "M " +days + "D ago";
    }
    else{
      if(days===0){
        return "Just Minutes ago";
      }else{
        return days + "D ago";
      }
    }
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  useEffect(() => {
    if (search.trim() === '') {
      setFilteredPosts(Blogs);
    } else {
      const filtered = Blogs.filter((post) =>
        post.Heading.toLowerCase().includes(search.toLowerCase()) || post.Description.toLowerCase().includes(search.toLowerCase()) || post.Category.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredPosts(filtered);
    }
  }, [search, Blogs]);

  const HandelSearchBlog = () => {
    setSearchClicked(!searchClicked);
    if(searchClicked){setSearch('');}
  }
  const ShowShareModal = (postLink) => {
    showSocialShare();
    SetAllSocialShareLinks(postLink);
  }
  return (
    <React.Fragment>
       <Helmet>
        <title>Blogs | ToolsTrain.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Blogs Description" />
        <meta name="keywords" content="Toolstrain, Blogs" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
      {socialicons? <SocialSharePopup hideSocialShare={hideSocialShare}/> : 
          <React.Fragment>
            <div className='bg-Transparent1'>
              <div className='relative container mx-auto max-w-7xl h-auto grid grid-cols-1'>
                  <div className=' grid grid-cols-1 mx-[5%] min-h-[50vh] py-10 bg-hero-pattern bg-no-repeat bg-center bg-cover opacity-95'>
                    {/* Content */}
                    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
                      <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
                          <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                              <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                              Blog
                              <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
                          </h2>
                          <h3 className='text-center text-sm sm:text-lg'>Popular and Trending Blog</h3>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div id='PinPost'>
              <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
                  <div className='grid grid-cols-1 mx-[3%] sm:mx-[5%]'>
                    <h3 className='pt-8 py-4 font-bold text-lg sm:text-xl'>Popular Post</h3>
                    <div className='w-full grid grid-cols-1 sm:grid-cols-[auto_1fr] gap-2 h-auto group rounded-md'>
                      <Link to='/blog/how-to-download-a-youtube-thumbnail' className='hover:cursor-pointer'>
                        <img className='w-full sm:max-w-[250px] md:max-w-[350px] rounded-lg' src={PopularBlogThumbnail} alt='Pin Post'/>
                      </Link>
                      <div className='overflow-auto grid grid-cols-1 gap-1 content-center px-3 py-2 border-l-[1px] border-b-[1px] border-r-[1px] rounded-md rounded-t-none sm:border-0 border-Primary'>
                        <div className='grid grid-cols-[auto_auto] justify-between items-center' id='Categories'>
                          <small className='underline hover:decoration-dotted cursor-pointer text-xs border border-Primary px-2 py-1 rounded-md hover:bg-Primary hover:text-White tracking-widest'>Tools</small>
                          <div className='border border-Primary hover:bg-Primary rounded-lg' onClick={()=>ShowShareModal(currentHostname+'/blog/how-to-download-a-youtube-thumbnail')}>
                            <ShareIcon className="fill-Primary hover:fill-White w-full h-auto max-w-[28px] p-1"/>
                          </div>
                        </div>
                        <Link to='/blog/how-to-download-a-youtube-thumbnail' className='hover:underline'>
                          <h4 className='font-bold text-lg sm:text-xl mt-2'>How to download a youtube thumbnail. </h4>
                        </Link>
                        <p className='line-clamp-2 text-sm'>How to download a YouTube thumbnail? Step-by-step guide for these 2 Free ways to download any thumbnail video from YouTube!</p>
                        <div className='grid grid-cols-[auto_auto] justify-between mt-2 mb-1'>
                          <time className='text-sm'>{DateIntoYMD('2024-02-22')}</time>
                          <Link to='/blog/how-to-download-a-youtube-thumbnail' className='hidden text-sm group-hover:grid group-hover:text-Primary hover:underline'>Read more</Link>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
              <div className='grid grid-cols-1 mx-[3%] sm:mx-[5%]'>
                <hr className='border border-Primary mt-7'/>
              </div>
            </div>
            <div id='AllPosts'>
              <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
                <div className='grid grid-cols-1 mx-[3%] sm:mx-[5%]'>
                  <div className='grid grid-cols-[auto_auto] justify-between items-center'>
                    <h3 className='py-4 font-bold text-lg sm:text-xl'>All Blog</h3>
                    {searchClicked? 
                    <div className='grid grid-cols-[1fr_auto] gap-2'>
                      <input className='w-32 sm:w-full text-Black outline-none border-b-2 border-Primary px-2' placeholder='Search ...' value={search} onChange={(e) => setSearch(e.target.value)} />
                      <div className='border border-Primary hover:bg-Primary rounded-lg' onClick={HandelSearchBlog}>
                        <CloseIcon className="fill-Primary hover:fill-White w-full h-auto max-w-[28px] p-1"/>
                      </div>
                    </div>: 
                    <div className='border border-Primary hover:bg-Primary rounded-lg' onClick={HandelSearchBlog}>
                      <SearchIcon className="fill-Primary hover:fill-White w-full h-auto max-w-[28px] p-1"/>
                    </div>
                    }
                  </div>
                  <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-auto-fit'>
                  {filteredPosts && filteredPosts.map((item, index) => (
                    <div key={index} className='w-full grid grid-cols-1 gap-2 h-auto group shadow-lg rounded-md'>
                      <Link to={item.PostLink} className='hover:cursor-pointer'>
                        <img className='w-full h-auto rounded-lg' src={item.Thumbnail} alt='Pin Post'/>
                      </Link>
                      <div className='overflow-auto grid grid-cols-1 gap-1 content-center px-3 py-2'>
                        <div className='grid grid-cols-[auto_auto] justify-between items-center' id='Categories'>
                          <small className='underline hover:decoration-dotted cursor-pointer text-xs border border-Primary px-2 py-1 rounded-md hover:bg-Primary hover:text-White tracking-widest'>{item.Category}</small>
                          <div className='border border-Primary hover:bg-Primary rounded-lg' onClick={()=>ShowShareModal(currentHostname+item.PostLink)}>
                            <ShareIcon className="fill-Primary hover:fill-White w-full h-auto max-w-[28px] p-1"/>
                          </div>
                        </div>
                        <Link to={item.PostLink} className='hover:underline'>
                          <h4 className='font-bold text-lg sm:text-xl mt-2'>{item.Heading}</h4>
                        </Link>
                        <p className='line-clamp-2 text-sm'>{item.Description}</p>
                        <div className='grid grid-cols-[auto_auto] justify-between mt-2 mb-1'>
                          <time className='text-sm'>{DateIntoYMD(item.Date)}</time>
                          <Link to={item.PostLink} className='hidden text-sm group-hover:grid group-hover:text-Primary hover:underline'>Read more</Link>
                        </div>
                      </div>
                    </div>
                  ))}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
      }
        {/* 
        <div id='Trending'>
          Popular/Trending Posts
        </div>
        <div id='Trending'>
          Discover Posts
        </div> */}
        <WhyUs />
    </React.Fragment>
  )
}

export default Blog