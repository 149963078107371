import React, { useEffect } from 'react'
import CategorySliderFrame from '../sections/CategorySliderFrame'
import CategorySliderFrame1 from '../sections/CategorySliderFrame1'
import WhyUs from '../sections/WhyUs'
import { Helmet } from 'react-helmet';
import CategorySliderFrame3 from '../sections/CategorySliderFrame3'
import PasswordGeneratorTool from '../sections/Tool Section/PasswordGenratorTool';

const PasswordGenerator = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Password Generator | Password | Tools Train.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Safeguard your digital world with a strong random Lastpass password generator. Protect your accounts effectively." />
        <meta name="keywords" content="" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
        <div className='bg-Transparent1'>
          <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
              <div className='grid grid-cols-1 mx-[5%] py-[25%] bg-hero-pattern bg-no-repeat bg-center bg-cover opacity-95'>
                <PasswordGeneratorTool />
              </div>
          </div>
        </div>
        <CategorySliderFrame />
        <CategorySliderFrame1 />
        <CategorySliderFrame3 />
        <WhyUs />
    </React.Fragment>
  )
}

export default PasswordGenerator
