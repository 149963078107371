import React from 'react'

export const Small = ({txt}) => {
  return (
    <small className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl underline hover:decoration-dotted hover:text-White cursor-pointer border hover:bg-Primary border-Primary tracking-widest mr-2 px-2 py-1 rounded-md">
        {txt}
    </small>
  )
}

// inline-block  
// text-xs  px-2 py-1 rounded-md 