import React, { useEffect } from 'react'
import CategorySliderFrame from '../sections/CategorySliderFrame'
import CategorySliderFrame1 from '../sections/CategorySliderFrame1'
import WhyUs from '../sections/WhyUs'
import { Helmet } from 'react-helmet';
import InvoiceGenratorTool from '../sections/Tool Section/InvoiceGenratorTool'
import CategorySliderFrame3 from '../sections/CategorySliderFrame3';

const InvoiceGenrator = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Manage your finances seamlessly with our online free invoice generator. Create professional invoices hassle-free. | Tools Train.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Compress image files without compromise at Tools Train. Reduce file sizes while maintaining quality with our efficient image file compression tool. Get it." />
        <meta name="keywords" content="image file compressor, compress image size, image optimization, reduce image file size" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
        <div className='bg-Transparent1'>
          <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
              <div className=' grid grid-cols-1 mx-[5%] py-[25%] bg-hero-pattern bg-no-repeat bg-center bg-cover opacity-95'>
                <InvoiceGenratorTool />
              </div>
          </div>
        </div>
        <CategorySliderFrame />
        <CategorySliderFrame1 />
        <CategorySliderFrame3 />
        <WhyUs />
    </React.Fragment>
  )
}

export default InvoiceGenrator