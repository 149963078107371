import {createContext} from 'react';
import Blog1YtThumnail from '../assets/images/what-is-a-url-shortener Toolstrain.com.jpeg';
import Blog2YtThumnail from '../assets/images/Youtube Thumbnail Downloader.jpeg';
import Blog3YtThumnail from '../assets/images/how to download a youtube thumbnail.png';
import TinyBlogImage from '../assets/images/tinyurl vs Bitly.png';
import bestfreeurlshortner from '../assets/images/best_free_url_shortner.png';
import ConvertAnImageTotext from '../assets/images/Convert_An_Image_To_Text.png';

export const BlogsContext = createContext();
const BlogsState = (props) => {
    // Categories: Tools, Technology, Social
    const BlogsContextDetails = [
        {
          Thumbnail: Blog1YtThumnail,
          Category: 'Social',
          Heading: 'What is a URL Shortener: History, Benefits, and How to Find One',
          Description: 'Find popular services like Toolstrain, TinyURL, Ow.ly, Rebrandly, and more. Choose the right shortener for your needs and use it effectively to enhance your online presence.',
          Date:'2024-02-15',
          PostLink: '/blog/what-is-a-url-shortener',
        },
        {
          Thumbnail: Blog2YtThumnail,
          Category: 'Tools',
          Heading: 'How to download a youtube thumbnail',
          Description: 'How to download a YouTube thumbnail? Step-by-step guide for these 2 Free ways to download any thumbnail video from YouTube!',
          Date:'2024-02-22',
          PostLink: '/blog/how-to-download-a-youtube-thumbnail',
        },
        {
          Thumbnail: Blog3YtThumnail,
          Category: 'Social',
          Heading: 'How To Make Linkedin Url Shortener - Free Toolstrain Url Shortener',
          Description: 'How do I get a cleaner URL on LinkedIn? The question we’ve got the most! LinkedIn is a powerful platform for networking, job hunting, and showcasing your professional achievements. However, the default LinkedIn profile URL can be quite lengthy and cumbersome. Wouldn’t it be great if you could create a custom, shorter URL that’s easy to share and remember? Well, you’re in luck!  In this article, we’ll walk you through the process of making your own LinkedIn URL shortener using free tools.',
          Date:'2024-02-29',
          PostLink: '/blog/How-To-Make-Linkedin-Url-Shortener-Free-Toolstrain-Url-Shortener',
        },
        {
          Thumbnail: TinyBlogImage,
          Category: 'Tools',
          Heading: 'TinyURL vs Bitly - A Comprehensive Comparison Which is Better?',
          Description: 'Comparing Bitly vs TinyURL for your link management needs and decide which URL shortener is right for you.',
          Date:'2024-03-07',
          PostLink: '/blog/tinyurl-vs-bitly',
        },
        {
          Thumbnail: bestfreeurlshortner,
          Category: 'Tools',
          Heading: 'Best Free URL Shortener API - Toolstrain',
          Description: 'Best free URL shortener API solution from Toolstrain - T.LY, Bitly, Rebrandly which one should choose? Get Answer Now!',
          Date:'2024-03-28',
          PostLink: '/blog/free-url-shortener-api',
        },
        {
          Thumbnail: ConvertAnImageTotext,
          Category: 'Tools',
          Heading: 'How Do I Convert an Image to Text Ai? - Toolstrain',
          Description: 'Convert Image to Text Ai Tools is What Every Student, Marketer and Business Owners Need. In this you will learn How You can easily Convert Any Picture to Text.',
          Date:'2024-04-28',
          PostLink: '/blog/convert-image-to-text-ai',
        },
    ];
    return (
        <BlogsContext.Provider value={BlogsContextDetails}>
            {props.children}
        </BlogsContext.Provider>
    )
}
export default BlogsState;