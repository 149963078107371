import React, { useState } from 'react';

const WordsCounterTool = () => {
  const [inputText, setInputText] = useState('');

  const countWords = () => {
    const words = inputText.split(/\s+/).filter((word) => word !== '');
    return words.length;
  };

  const countCharacters = () => {
    return inputText.length;
  };

  const countParagraphs = () => {
    const paragraphs = inputText.split('\n').filter((paragraph) => paragraph.trim() !== '');
    return paragraphs.length;
  };

  const countLines = () => {
    const lines = inputText.split('\n').filter((line) => line.trim() !== '');
    return lines.length;
  };

  const countLinks = () => {
    // Use a regular expression to find links in the text
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const links = inputText.match(linkRegex);
    return links ? links.length : 0;
  };

  return (
    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
    <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
        <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
            <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
            Pdf Word Counter Tool Online: Keep Track of Your Writing Progress
            <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
        </h2>
        <h3 className='text-center text-sm sm:text-lg'>Keep track of your writing progress with our PDF Word counter tool online. Monitor word count and productivity effortlessly.</h3>
    </div>
    <div className="grid grid-cols-1 gap-4">
        <label className="grid grid-cols-1 border-2 border-Primary p-2 rounded-lg bg-White">
            <textarea 
                rows={10}
                className='outline-none' 
                placeholder="Enter text or paragraphs"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
            ></textarea>
        </label>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 bg-All-Primary px-2 py-4 rounded-md border border-Primary'>
            <p><span className='font-[550]'>Word Count:</span> {countWords()}</p>
            <p><span className='font-[550]'>Character Count:</span> {countCharacters()}</p>
            <p><span className='font-[550]'>Paragraph Count:</span> {countParagraphs()}</p>
            <p><span className='font-[550]'>Line Count:</span> {countLines()}</p>
            <p><span className='font-[550]'>Link Count:</span> {countLinks()}</p>
        </div>
    </div>
</div>
  );
};

export default WordsCounterTool;
