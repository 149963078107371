import React from 'react'

export const H2 = ({txt}) => {
  return (
    <h2 className='text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold uppercase relative mt-8 mb-2'>
        <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
            {txt}
        <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
    </h2>
  )
}
