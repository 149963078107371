import React, { useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import { toCanvas } from 'html-to-image';
// Need to improve the quality because google imag did't able to read the generated QRCode. Currently we need to install app from playstore.

const Website = ({inputValue, setInputValue}) => {
    const handelChange = (e) => {
        setInputValue({ ...inputValue, Website: e.target.value });
    }
    return (
        <div className='grid grid-cols-1'>
            <label htmlFor='Webiste' className='font-bold'>Website URL:</label>
            <input
                key="website-url" // Add key prop here
                name='Webiste'
                id='Webiste'
                type="text"
                value={inputValue.Website}
                onChange={handelChange}
                className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
                placeholder="Paste a URL below to link with your QR code"
            />
        </div>
    )
};

const Twitter = ({inputValue, setInputValue}) => {
    const handelChange = (e) => {
        setInputValue({ ...inputValue, Twitter: e.target.value });
    }
    return (
        <div className='grid grid-cols-1'>
            <label htmlFor='Twitter' className='font-bold'>X message:</label>
            <textarea className="grid grid-cols-1 justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White w-full" cols={10} rows={5} value={inputValue.Twitter} onChange={handelChange} placeholder='Type or past a tweet' id='Twitter'></textarea>
        </div>
    );
};

const Facebook = ({inputValue, setInputValue}) => {
    const handelChange = (e) => {
        setInputValue({ ...inputValue, Facebook: e.target.value });
    }
    return (
    <div className='grid grid-cols-1'>
        <label htmlFor='Facebook'>URL you want to share: </label>
        <input
            name='Facebook'
            id='Facebook'
            type="text"
            value={inputValue.Facebook}
            onChange={handelChange}
            className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
            placeholder="Type or past a link (URL)"
        />
    </div>
    );
};
// const Pinterest  = ({inputValue, setInputValue}) => {
//     const handleChange1 = (e) => {
//         setInputValue({ 
//             ...inputValue, 
//             Pinterest: {
//                 ...inputValue.Pinterest,
//                 url: e.target.value
//             } 
//         });
//     };

//     const handleChange2 = (e) => {
//         setInputValue({ 
//             ...inputValue, 
//             Pinterest: {
//                 ...inputValue.Pinterest,
//                 source: e.target.value
//             } 
//         });
//     };

//     const handleChange3 = (e) => {
//         setInputValue({ 
//             ...inputValue, 
//             Pinterest: {
//                 ...inputValue.Pinterest,
//                 description: e.target.value
//             } 
//         });
//     };

//    return (
//     <div className='grid grid-cols-1'>
//         <div className='grid grid-cols-1'>
//             <label htmlFor='Pinterest'>Image URL: </label>
//             <input
//                 name='Pinterest'
//                 id='Pinterest'
//                 type="text"
//                 value={inputValue.Pinterest.url}
//                 onChange={handleChange1}
//                 placeholder="Link to image you want to share"
//             />
//         </div>
//         <div className='grid grid-cols-1'>
//             <label htmlFor='Source'>Source (optional)</label>
//             <input
//                 name='Source'
//                 id='Source'
//                 type="text"
//                 value={inputValue.Pinterest.source}
//                 onChange={handleChange2}
//                 placeholder="Link to the source"
//             />
//         </div>
//         <div className='grid grid-cols-1'>
//             <label htmlFor='Description'>Description (optional)</label>
//             <input
//                 name='Description'
//                 id='Description'
//                 type="text"
//                 value={inputValue.Pinterest.description}
//                 onChange={handleChange3}
//                 placeholder="Description of your pin"
//             />
//         </div>
//     </div>
//    );
// };
const Linkedin = ({inputValue, setInputValue}) => {
    const handelChange = (e) => {
        setInputValue({ ...inputValue, Linkedin: e.target.value });
    }
    return (
        <div className='grid grid-cols-1'>
            <label htmlFor='Linkedin'>URL you want to share:</label>
            <input
                name='Linkedin'
                id='Linkedin'
                type="text"
                value={inputValue.Linkedin}
                onChange={handelChange}
                placeholder="Type or past a link (URL)"
                className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
            />
        </div>
    );
};

const Whatsapp = ({ inputValue, setInputValue }) => {
    const handleChangePhoneNumber = (e) => {
        setInputValue({ ...inputValue, Whatsapp: { ...inputValue.Whatsapp, phoneNumber: e.target.value } });
    };

    const handleChangeMessage = (e) => {
        setInputValue({ ...inputValue, Whatsapp: { ...inputValue.Whatsapp, message: e.target.value } });
    };

    return (
        <div className='grid grid-cols-1'>
            <div className='grid grid-cols-1'>
                <label htmlFor='WhatsappPhoneNumber'>Recipient phone number:</label>
                <input
                    name='WhatsappPhoneNumber'
                    id='WhatsappPhoneNumber'
                    type="text"
                    value={inputValue.Whatsapp.phoneNumber}
                    className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
                    onChange={handleChangePhoneNumber}
                    placeholder="e.g. +44654321"
                />
            </div>
            <div className='grid grid-cols-1'>
                <label htmlFor='WhatsappMessage'>Content you want to share:</label>
                <input
                    name='WhatsappMessage'
                    id='WhatsappMessage'
                    type="text"
                    value={inputValue.Whatsapp.message}
                    onChange={handleChangeMessage}
                    className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
                    placeholder="I want to share with you this..."
                />
            </div>
        </div>
    );
};
const Email = ({ inputValue, setInputValue }) => {
    const handleChangeRecipient = (e) => {
        setInputValue({ ...inputValue, Email: { ...inputValue.Email, recipient: e.target.value } });
    };

    const handleChangeSubject = (e) => {
        setInputValue({ ...inputValue, Email: { ...inputValue.Email, subject: e.target.value } });
    };

    const handleChangeBody = (e) => {
        setInputValue({ ...inputValue, Email: { ...inputValue.Email, body: e.target.value } });
    };

    return (
        <div className='grid grid-cols-1'>
            <div className='grid grid-cols-1'>
                <label htmlFor='EmailRecipient'>Recipient email address:</label>
                <input
                    name='EmailRecipient'
                    id='EmailRecipient'
                    type="email"
                    value={inputValue.Email.recipient}
                    onChange={handleChangeRecipient}
                    className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
                    placeholder="Enter recipient email address"
                />
            </div>
            <div className='grid grid-cols-1'>
                <label htmlFor='EmailSubject'>Subject:</label>
                <input
                    name='EmailSubject'
                    id='EmailSubject'
                    type="text"
                    value={inputValue.Email.subject}
                    onChange={handleChangeSubject}
                    className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
                    placeholder="Enter email subject"
                />
            </div>
            <div className='grid grid-cols-1'>
                <label htmlFor='EmailBody'>Body:</label>
                <textarea
                    name='EmailBody'
                    id='EmailBody'
                    value={inputValue.Email.body}
                    onChange={handleChangeBody}
                    className="grid grid-cols-1 justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White w-full"
                    placeholder="Enter email body"
                />
            </div>
        </div>
    );
};

// const Telephone = ({ inputValue, setInputValue }) => {
//     const handleChangePhoneNumber = (e) => {
//         setInputValue({ ...inputValue, Telephone: e.target.value });
//     };

//     return (
//         <div className='grid grid-cols-1'>
//             <div className='grid grid-cols-1'>
//                 <label htmlFor='PhoneNumber'>Phone number:</label>
//                 <input
//                     name='PhoneNumber'
//                     id='PhoneNumber'
//                     type="tel"
//                     value={inputValue.Telephone}
//                     onChange={handleChangePhoneNumber}
//                     placeholder="Enter phone number"
//                 />
//             </div>
//         </div>
//     );
// };


const Telephone = ({ inputValue, setInputValue }) => {
    const handleChangeCountryCode = (e) => {
        setInputValue({ 
            ...inputValue, 
            Telephone: {
                ...inputValue.Telephone,
                countryCode: e.target.value
            }
        });
    };

    const handleChangePhoneNumber = (e) => {
        setInputValue({ 
            ...inputValue, 
            Telephone: {
                ...inputValue.Telephone,
                number: e.target.value
            }
        });
    };

    return (
        <div className='grid grid-cols-1'>
            <div className='grid grid-cols-1'>
                <label htmlFor='CountryCode'>Country code:</label>
                <input
                    name='CountryCode'
                    id='CountryCode'
                    type="text"
                    value={inputValue.Telephone.countryCode}
                    onChange={handleChangeCountryCode}
                    className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
                    placeholder="+1"
                />
            </div>
            <div className='grid grid-cols-1'>
                <label htmlFor='PhoneNumber'>Phone number:</label>
                <input
                    name='PhoneNumber'
                    id='PhoneNumber'
                    type="tel"
                    value={inputValue.Telephone.number}
                    onChange={handleChangePhoneNumber}
                    placeholder="Enter phone number"
                    className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
                />
            </div>
        </div>
    );
};

const SMS = ({ inputValue, setInputValue }) => {
    const handleChangePhoneNumber = (e) => {
        setInputValue({ ...inputValue, SMS: { ...inputValue.SMS, phoneNumber: e.target.value } });
    };

    const handleChangeMessage = (e) => {
        setInputValue({ ...inputValue, SMS: { ...inputValue.SMS, message: e.target.value } });
    };

    return (
        <div className='grid grid-cols-1'>
            <div className='grid grid-cols-1'>
                <label htmlFor='PhoneNumber'>Phone number:</label>
                <input
                    name='PhoneNumber'
                    id='PhoneNumber'
                    type="text"
                    value={inputValue.SMS.phoneNumber}
                    onChange={handleChangePhoneNumber}
                    placeholder="Enter phone number"
                    className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
                />
            </div>
            <div className='grid grid-cols-1'>
                <label htmlFor='Message'>Message:</label>
                <textarea
                    name='Message'
                    id='Message'
                    value={inputValue.SMS.message}
                    onChange={handleChangeMessage}
                    placeholder="Enter message"
                    className="grid grid-cols-1 justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White w-full"
                    rows={4}
                />
            </div>
        </div>
    );
};
export const QRCodeGeneratorTool = () => {
    const [qrCodeType, setQrCodeType] = useState('Website');
    const [inputValue, setInputValue] = useState({ Website: '', Twitter: '', Facebook: '', Pinterest: {
        url: '',
        source: '', 
        description:'',
    }, Linkedin:'',  Whatsapp: {
        phoneNumber: '', 
        message: ''
    }, Email: {
        recipient: '', 
        subject: '', 
        body: '',
    }, Telephone: {
        countryCode: '', 
        number: '',
    }, SMS: {
        phoneNumber: '', 
        message: '',
    } });
      // Ref for the QR code component
    const qrCodeRef = useRef();
    const handleDownload = () => {
        // Reference to the QR code container element
        const qrCodeContainer = document.getElementById('qr-code-container');

         // Convert the container to a canvas
        toCanvas(qrCodeContainer)
        .then(function (canvas) {
        // Create a temporary anchor element
        const link = document.createElement('a');
        // Convert canvas to data URL and set it as href
        link.href = canvas.toDataURL();
        link.download = 'qrcode.png'; // You can change the filename here
        link.click();
        })
        .catch(function (error) {
        console.error('Error generating QR code image:', error);
        });
    };
    const handleReset = () => {
        setInputValue({
            Website: '',
            Twitter: '',
            Facebook: '',
            Pinterest: {
                url: '',
                source: '',
                description:'',
            },
            Linkedin:'',
            Whatsapp: {
                phoneNumber: '',
                message: ''
            },
            Email: {
                recipient: '',
                subject: '',
                body: '',
            },
            Telephone: {
                countryCode: '',
                number: '',
            },
            SMS: {
                phoneNumber: '',
                message: '',
            }
        });
    };
    console.log("Data: "+ inputValue.Pinterest.description);
    const [message, setMessage] = useState("");
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');
    const [foregroundColor, setForegroundColor] = useState('#000000');
    const [margin, setMargin] = useState(false);
    const [size, setSize] = useState(150);
    const [logo, setLogo] = useState(null);

    const handleQrCodeType = (e) => {
        setQrCodeType(e.target.value);
    };
    const QRLogo = {
        src: logo,
        alt: 'Logo by user',
        excavate: true,
        width: size * 0.3,
        height: size * 0.3,
    }
    const uploadLogo = (e) =>{
        try{
            setLogo(URL.createObjectURL(e.target.files[0]));
            setMessage("Logo Uploaded Success!")
        }
        catch{
            console.log("Error");
            setMessage("Got error during uploading, Please Reupload!")
        }
    }
    return (
        <React.Fragment>
            <div className='grid grid-cols-1 overflow-hidden mx-auto gap-4 w-full max-w-sm'> {/* 3 */}
                <div className='grid grid-cols-1 items-center content-center gap-4 w-full'>
                    <div className='grid grid-cols-1 items-start gap-1 w-full'>
                        <label htmlFor='QRType' className='font-bold'>Select QR Code Type: </label>
                        <select name='QRType' id='QRType' value={qrCodeType} className='grid grid-cols-1 gap-2 rounded-sm overflow-hidden outline-none p-2 w-full' onChange={handleQrCodeType}> 
                            <option className='w-full px-4' value="Website">Website</option>
                            <option className='w-full px-4' value="Twitter">Twitter</option>
                            <option className='w-full px-4' value="Facebook">Facebook</option>
                            {/* <option className='w-full px-4' value="Pinterest">Pinterest</option> */}
                            <option className='w-full px-4' value="Linkedin">Linkedin</option>
                            <option className='w-full px-4' value="Whatsapp">Whatsapp</option>
                            <option className='w-full px-4' value="Email">Email</option>
                            <option className='w-full px-4' value="Telephone">Telephone</option>
                            <option className='w-full px-4' value="SMS">SMS</option>
                        </select>
                    </div>
                    {/* <Component /> */}
                    {qrCodeType === 'Website' && <Website inputValue={inputValue} setInputValue={setInputValue} />}
                    {qrCodeType === 'Twitter' && <Twitter inputValue={inputValue} setInputValue={setInputValue} />}
                    {qrCodeType === 'Facebook' && <Facebook inputValue={inputValue} setInputValue={setInputValue} />}
                    {/* {qrCodeType === 'Pinterest' && <Pinterest inputValue={inputValue} setInputValue={setInputValue} />} */}
                    {qrCodeType === 'Linkedin' && <Linkedin inputValue={inputValue} setInputValue={setInputValue} />}
                    {qrCodeType === 'Whatsapp' && <Whatsapp inputValue={inputValue} setInputValue={setInputValue} />}
                    {qrCodeType === 'Email' && <Email inputValue={inputValue} setInputValue={setInputValue} />}
                    {qrCodeType === 'Telephone' && <Telephone inputValue={inputValue} setInputValue={setInputValue} />}
                    {qrCodeType === 'SMS' && <SMS inputValue={inputValue} setInputValue={setInputValue} />}
      
                </div>
                <div className='grid grid-cols-1 gap-2'>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='grid grid-cols-1 gap-1'>
                            <label htmlFor='BgColor' className='font-bold'>Bg Color:</label>
                            <div className='grid grid-cols-[auto_auto] justify-between items-center relative bg-White rounded-md h-auto w-full p-2'>
                                <p>{backgroundColor}</p>
                                <input
                                    type="color"
                                    name='BgColor'
                                    id='BgColor'
                                    value={backgroundColor}
                                    className='w-10 h-7 rounded-full border-none bg-ActualTransparent'
                                    onChange={(e) => setBackgroundColor(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='grid grid-cols-1 gap-1'>
                            <label htmlFor='FgColor' className='font-bold'>Color:</label>
                            <div className='grid grid-cols-[auto_auto] justify-between items-center relative bg-White rounded-md h-auto w-full p-2'>
                                <p>{backgroundColor}</p>
                                <input
                                    type="color"
                                    name='FgColor'
                                    id='FgColor'
                                    className='w-10 h-7 rounded-full border-none bg-ActualTransparent'
                                    value={foregroundColor}
                                    onChange={(e) => setForegroundColor(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1'>
                        <label htmlFor='Margin' className='font-bold'>Margin: </label>
                        <select 
                            onChange={(e)=> setMargin(e.target.value)} 
                            id='Margin' 
                            className='grid grid-cols-1 gap-2 rounded-sm overflow-hidden outline-none p-2'
                            name='Margin'> 
                            <option className='w-full px-4 py-2' value="">Select Margin</option>
                            <option className='w-full px-4 py-2' value={true}>Yes</option>
                            <option className='w-full px-4 py-2' value="">No</option>
                        </select>
                    </div>
                    <div className='grid grid-cols-1'>
                        <label htmlFor='Size' className='font-bold'>Size: </label>
                        <input
                            type="number"
                            id="Size"
                            name='Size'
                            value={size}
                            className='border-b-2 border-Primary focus:border-Black outline-none bg-All-Primary px-3 py-2 w-full rounded-tl-md rounded-tr-md'
                            onChange={(e) => setSize(e.target.value)}
                            placeholder="Size"
                        />
                    </div>
                    <div className='grid grid-cols-1 mt-3'>
                        <label className="grid grid-cols-[auto_1fr] text-center w-full justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White">
                            <svg className="w-8 h-8" fill="#429FC5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span className="mt-2 text-base leading-normal text-Primary pb-1">Select Logo</span>
                            <input type='file' className="hidden" id="Logo" name='Logo' accept="image/*" onChange={uploadLogo} />
                        </label>
                        {logo && <p className='text-center p-2'>{message}</p>}
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-1 justify-items-center w-full overflow-auto' id="qr-code-container">
                {/* https://www.pinterest.pt/pin-builder/?description=desc............&dummy=&dummy=0.27901767330275473&media=https%3A%2F%2Fqr.rebrandly.com%2Fv1%2Fqrcode%3Fmargin%3D0&method=button&shortUrl=rb.gy&shortUrl=rb.gy&size=128&size=128&source=rb.gy&source=rb.gy&url=https%3A%2F%2Fqr.rebrandly.com%2Fv1%2Fqrcode%3Fmargin%3D0 */}
                    <QRCode
                        ref={qrCodeRef}
                        id="qr-canvas"
                        value={qrCodeType==="Twitter" ? `https://twitter.com/intent/tweet?text=${encodeURIComponent(inputValue.Twitter)}` 
                        : qrCodeType==="Facebook" ? `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(inputValue.Facebook)}` 
                        : qrCodeType==="Pinterest" ? `https://www.pinterest.pt/pin-builder/?url=${encodeURIComponent(inputValue.Pinterest.url)}&media=${encodeURIComponent(inputValue.Pinterest.source)}&description=${encodeURIComponent(inputValue.Pinterest.description)}` 
                        : qrCodeType==="Linkedin" ? `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(inputValue.Linkedin)}` : qrCodeType==="Whatsapp" ? `https://wa.me/${encodeURIComponent(inputValue.Whatsapp.phoneNumber)}?text=${encodeURIComponent(inputValue.Whatsapp.message)}` 
                        : qrCodeType==="Email" ? `mailto:${encodeURIComponent(inputValue.Email.recipient)}?subject=${encodeURIComponent(inputValue.Email.subject)}&body=${encodeURIComponent(inputValue.Email.body)}` 
                        : qrCodeType==="Telephone" ? `tel:${inputValue.Telephone.countryCode}${encodeURIComponent(inputValue.Telephone.number)}` 
                        : qrCodeType==="SMS" ? `sms:${inputValue.SMS.phoneNumber}?body=${encodeURIComponent(inputValue.SMS.message)}`
                        : inputValue[qrCodeType]}
                        bgColor={backgroundColor}
                        fgColor={foregroundColor}
                        size={parseInt(size)}
                        level={'H'}
                        includeMargin={margin}
                        margin={margin === true ? 1 : 0}
                        imageSettings={QRLogo}
                        renderAs={'canvas'}
                        className={margin===true ? 'border-2 rounded-lg border-dashed border-Secoundary': 'border-2 rounded-lg border-dashed border-Secoundary'}
                    />
            </div>
            <div className='grid grid-cols-1 items-center justify-items-center'>
                <button
                    className='border-2 bg-Primary px-2 py-1 my-1 mr-1 rounded-md hover:text-Primary hover:bg-Transparent text-White inline-block'
                    onClick={handleDownload}
                >
                    <span className='inline-block'>Download QR Code</span>
                </button>
                <button
                    className='border-2 border-Primary px-2 py-1 my-1 mr-1 rounded-md text-Primary hover:bg-Primary hover:text-White inline-block'
                    onClick={handleReset}
                >
                    <span className='inline-block'>Reset</span>
                </button>
            </div>
        </React.Fragment>
    );
};