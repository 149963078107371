import React from 'react';
import {Link} from 'react-router-dom';
import Logo from '../assets/logo/ToolsTrainLogo.png';
import InstagramIcon from '../assets/icons/instagram.svg';
import FacebookIcon from '../assets/icons/facebook.svg';
import LinkedIcon from '../assets/icons/linkedin.svg';

const Footer = () => {
  return (
    <div className='relative container mx-auto max-w-7xl grid grid-cols-1 z-0'>
        <div className='grid grid-cols-1 mx-[5%] mt-8 py-4'>
          {/* sm:grid-cols-[1.3fr_1fr_1fr] */}
            <ul className='grid grid-cols-1 md:grid-cols-2 gap-8 py-4 pb-10'>
                <li className='grid grid-cols-1 gap-4'>
                    <Link to="/" className='grid grid-cols-[auto_1fr] items-center justify-items-start gap-3'>
                        <img alt='Tools Train Logo' src={Logo} className='w-12 sm:w-14 h-auto App-logo'/> 
                        <h6 className='uppercase text-Primary font-bold text-lg'>Tools Train</h6>
                    </Link>
                    <p className='text-justify'>Toolstrain offers a suite of productivity tools beyond just URL shortening, including a hashtag generator, image resizer, and more. </p>
                    <div className='socialIcons grid auto-cols-auto grid-flow-col justify-start gap-2'>
                      <a href="https://www.linkedin.com/company/tools-train/" target='_blank' rel="noopener noreferrer">
                        <img src={LinkedIcon} className='w-12 h-auto' alt='Linkedin Icon'/>
                      </a>
                      <a href='https://www.facebook.com/profile.php?id=61552993900736' target='_blank' rel="noopener noreferrer">
                        <img src={FacebookIcon} className='w-12 h-auto' alt='Facebook Icon'/>
                      </a>
                      <a href='https://www.instagram.com/toolstrain/' target='_blank' rel="noopener noreferrer">
                        <img src={InstagramIcon} className='w-12 h-auto' alt='Instagram Icon'/>
                      </a>
                    </div>
                </li> 
               <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                  <li className='grid grid-cols-1'>
                    <h3 className='text-xl font-bold pb-2'>Quick Tools</h3>
                    <ul className='grid grid-cols-1 gap-1 list-disc pl-4'>
                      <li>
                        <Link to="/web-services" className='hover:text-Primary'>
                          Web Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/text-assistance-Tool" className='hover:text-Primary'>
                          Text Assistance
                        </Link>
                      </li>
                      <li>
                        <Link to="/" className='hover:text-Primary'>
                          Url Shortener Free Online.
                        </Link>
                      </li>
                      <li>
                        <Link to="/bio" className='hover:text-Primary'>
                          Bio Links for Success.
                        </Link>
                      </li>
                      <li>
                        <Link to="/qr-code-generator" className='hover:text-Primary'>
                          Advanced QR Code Generator.
                        </Link>
                      </li>
                      <li>
                        <Link to="/youtube-thumbnail-downloader" className='hover:text-Primary'>
                          YouTube Thumbnail Downloader.
                        </Link>
                      </li>
                    </ul>
                  </li> 
                  <li className='grid grid-cols-1'>
                    <h3 className='text-xl font-bold pb-2'>Pages</h3>
                    <ul className='grid grid-cols-1 gap-1 list-disc pl-4'>
                      <li>
                        <Link to="/blog" className='hover:text-Primary'>
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" className='hover:text-Primary'>
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us" className='hover:text-Primary'>
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/disclaimer" className='hover:text-Primary'>
                          Disclaimer
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms-and-conditions" className='hover:text-Primary'>
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-and-policy" className='hover:text-Primary'>
                          Privacy & Policy
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/sitemap.xml" className='hover:text-Primary'>
                          Sitemap
                        </Link>
                      </li> */}
                    </ul>
                  </li> 
               </div>
            </ul>
            <p className='text-center py-2 text-sm'> Copyright &copy; 2024 <Link to="https://toolstrain.com/" target='_blank' className='text-Primary text-base hover:underline'>ToolsTrains</Link> | All rights are reserved.</p>
        </div>
    </div>
  )
}

export default Footer
