import React, { useRef, useState } from 'react';
import axios from 'axios';
import DummyFileUploader from '../../Utilities/DummyProgressBar';

const HashTagGenratorTool = () => {
  const [inputText, setInputText] = useState('');
  const [generatedHashtags, setGeneratedHashtags] = useState([]);
  const [suggestedHashtags, setSuggestedHashtags] = useState([]);
  const [suggestedHashtags1, setSuggestedHashtags1] = useState([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [message, setMessage] = useState('');

  const outputDivRef = useRef(null);

  const generateHashtags = () => {
    const words = inputText.split(/\s+/);
    const hashtags = words
        .filter((word) => word !== '') // Remove empty words
        .map((word) => `#${word}, `);
    setGeneratedHashtags(hashtags);
    suggestHashtags();
    suggestHashtags1();
  };

  const suggestHashtags = async () => {
    try {
        // Accept only one word
      const response = await axios.get(
        `https://api.datamuse.com/words?rel_trg=${inputText}`
      );
      const suggestions = response.data.slice(0, 5).map((item) => `#${item.word}, `);
      setSuggestedHashtags(suggestions);
    } catch (error) {
      console.error('Error fetching suggested hashtags:', error.message);
    }
  };

  const suggestHashtags1 = async () => {
    try {
      const response = await axios.get(
        `https://api.datamuse.com/sug?s=${inputText}`
      );
      const suggestions = response.data.map((item) => `#${item.word}, `);
      setSuggestedHashtags1(suggestions);
    } catch (error) {
      console.error('Error fetching suggested hashtags:', error.message);
    }
  };
  const handleInputChange = (e) => {
    // Allow only one word (no spaces)
    const input = e.target.value.trim();
    const onlyOneWord = input.split(/\s+/)[0];
    setInputText(onlyOneWord);
    setUploadCompleted(false);
    setGeneratedHashtags([]);
    setMessage("");
  };

  const handleUploadComplete = () => {
    setUploadCompleted(true);
  };
  // const copyToClipboard = () => {
  //   const outputField = document.getElementById('hashTagGenratorOutput');
  //   if (outputField) {
  //     outputField.select();
  //     document.execCommand('copy');
  //   }
  // };
  const copyToClipboard = () => {
    const outputDiv = outputDivRef.current;
    if (outputDiv) {
      const range = document.createRange();
      range.selectNode(outputDiv);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      console.log('Text copied to clipboard!');
      setMessage("Text Copied!")
    }
    else{
      setMessage("Text Copied Failed!")
    }

  };
  // useEffect(() => {
  //   generateHashtags();
  //   suggestHashtags();
  //   suggestHashtags1();
  // });

  return (
    <div>
        <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
            <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
                    <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                        <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                        Instagram Free Hashtag Generator | No Login Required - Toolstrain  
                        <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
                    </h2>
                    <h3 className='text-center text-sm sm:text-lg'>Boost your post 100x with our Instagram Free hashtag generator. Get Top Popular Trending #hashtags with Toolstrain Now!</h3>
                </div>
                <div className="grid grid-cols-[auto_auto] justify-center items-center gap-4">
                    <label className="grid grid-cols-1 justify-self-center gap-2 border-2 border-Primary rounded-lg bg-White">
                        <input
                            type='text'
                            placeholder="One Word Only"
                            value={inputText}
                            className='outline-none w-full py-2 px-2 rounded-lg'
                            onChange={handleInputChange}
                        />
                    </label>
                    <button
                        className='border-2 border-Primary px-2 py-2 rounded-md text-White bg-Primary hover:bg-White hover:text-Primary inline-block'
                        onClick={generateHashtags}
                    >
                        <span className=''>Generate</span>
                    </button>
                </div>
                {generatedHashtags.length>0 && <DummyFileUploader filename={generatedHashtags[0]} onUploadComplete={handleUploadComplete} />}
                {uploadCompleted && 
                <div className='bg-White border-2 border-Primary rounded-lg px-3 py-4' aria-rowcount={10} id='hashTagGenratorOutput' ref={outputDivRef}>
                    {generatedHashtags.map((hashtag, index) => (
                        <span key={index}>{hashtag}</span>
                    ))}
                    {suggestedHashtags.map((hashtag, index) => (
                        <span key={index}>{hashtag}</span>
                    ))}
                    {suggestedHashtags1.map((hashtag, index) => (
                        <span key={index}>{hashtag}</span>
                    ))}
                </div>}
                {uploadCompleted && <button
                  className='border-2 border-Primary px-2 py-1 my-1 mr-1 rounded-md text-Primary hover:bg-Primary hover:text-White inline-block justify-self-end'
                  onClick={copyToClipboard}
                >
                  <span className='hidden sm:inline-block'>Quick</span> <span className='inline-block'>Copy</span>
                </button>}
                <p className='text-center'>{message}</p>
            </div>
    </div>
  );
};

export default HashTagGenratorTool;
