// import React from 'react'

// export const AgeCalculatorTool = () => {
//   return (
//     <div>AgeCalculatorTool</div>
//   )
// }

import React, { useRef, useState } from 'react';

const AgeCalculatorTool = () => {
  const [birthDate, setBirthDate] = useState('');
  const [ageYears, setAgeYears] = useState(null);
  const [ageMonths, setAgeMonths] = useState(null);
  const [ageDays, setAgeDays] = useState(null);
  const [totalAgeMonths, setTotalAgeMonths] = useState(null);
  const [totalAgeDays, setTotalAgeDays] = useState(null);
  const [totalDaysinLife, setTotalDaysinLife] = useState(null);
//   const [totalAgeHours, setTotalAgeHours] = useState(null);
  const [ageInHours, setAgeInHours] = useState(null);
  const [ageInMinutes, setAgeInMinutes] = useState(null);
  const [ageInSecounds, setAgeInSeconds]= useState(null);
  const [error, setError] = useState('');
  const outputDivRef = useRef(null);

  const calculateAge = () => {
    try {
      const birthDateObj = new Date(birthDate);
      const currentDate = new Date();

      if (isNaN(birthDateObj.getTime())) {
        throw new Error('Invalid date. Please enter a valid date.');
      }

      if ( birthDateObj>=currentDate) {
        throw new Error('Please enter older date with respect to current date.');
      }

      const ageDiff = currentDate - birthDateObj;
      const ageDate = new Date(ageDiff);

      const years = Math.abs(ageDate.getUTCFullYear() - 1970);
      const months = ageDate.getUTCMonth();
      const days = ageDate.getUTCDate() - 1; // Subtracting 1 to adjust for day of birth

      setAgeYears(years);
      setAgeMonths(months);
      setAgeDays(days);

      // Calculate total age in months, days, hours, and minutes
      const totalMonths = years * 12 + months;
      const totalDays = ageDate.getUTCDate() - 1;
    //   const totalHours = ageDiff / (1000 * 60 * 60); // Convert milliseconds to hours
    //   const totalMinutes = ageDiff / (1000 * 60); // Convert milliseconds to minutes
      const totalSeconds = ageDiff / 1000; 

      const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
      const ageInDays = Math.floor(ageDiff / millisecondsPerDay);
      const ageInHours = ageInDays * 24;
      const ageInMinutes = ageInHours * 60;

      setAgeInHours(ageInHours);
      setAgeInMinutes(ageInMinutes);
      setTotalDaysinLife(ageInDays);

      setTotalAgeMonths(totalMonths);
      setTotalAgeDays(totalDays);
    //   setTotalAgeHours(totalHours);
      setAgeInSeconds(totalSeconds); 

      setError('');
    } catch (err) {
      setError(err.message);
      setAgeYears(null);
      setAgeMonths(null);
      setAgeDays(null);
      setTotalAgeMonths(null);
      setTotalAgeDays(null);
    //   setTotalAgeHours(null);
      setAgeInHours(null);
      setAgeInMinutes(null);
      setAgeInSeconds(null);
    }
  };
  const copyToClipboard = () => {
    const outputDiv = outputDivRef.current;
    if (outputDiv) {
      const range = document.createRange();
      range.selectNode(outputDiv);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      console.log('Text copied to clipboard!');
      setError("Text Copied!")
    }
    else{
      setError("Text Copied Failed!")
    }

  };
  return (
    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
        <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
            <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                Age Calculator By Date Of Birth: Unlock Your Life Story
                <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
            </h2>
            <h3 className='text-center text-sm sm:text-lg'>Unlock your life story with our age calculator by date of birth. Discover your age and milestones effortlessly</h3>
        </div>
        <div className="grid grid-cols-[auto_auto] justify-center items-center gap-4">
            <label className="grid grid-cols-1 justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White">
                <input type="date" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} className='outline-none text-Primary bg-White' />
            </label>
            <button
                className='border-2 border-Primary px-4 py-2 my-1 mr-1 rounded-md text-White bg-Primary hover:bg-White hover:text-Primary inline-block'
                onClick={calculateAge}
              >
                <span className='hidden sm:inline-block'>Age</span> <span className='inline-block'>Calculate</span>
              </button>
        </div>
        {ageYears !== null && (
            <div className='grid grid-cols-1'>
                <div className='bg-White border-2 border-Primary rounded-lg px-3 py-4' ref={outputDivRef}>
                    <p>Total age: {ageYears} years {ageMonths} months {ageDays} days</p>
                    {totalAgeMonths !== null && totalAgeDays !== null && (
                      <p>Total age: {totalAgeMonths} months {totalAgeDays} days</p>
                      )}
                    <p>Total age: {totalDaysinLife} Days</p>
                    {ageInHours !== null && (
                        <p>Total age in hours: {ageInHours} hours</p>
                    )}
                    {ageInMinutes !== null && (
                        <p>Total age in minutes: {ageInMinutes} minutes</p>
                    )}
                    {ageInSecounds !== null && (
                        <p>Total age in Secounds: {ageInMinutes !==0 ? ageInSecounds : 0} secounds</p>
                    )}
                </div>
                <button
                className='border-2 border-Primary px-2 py-1 my-3 rounded-md text-Primary hover:bg-Primary hover:text-White inline-block justify-self-end'
                onClick={copyToClipboard}
                >
                  <span className='hidden sm:inline-block'>Quick</span> <span className='inline-block'>Copy</span>
                </button>
            </div>
        )}
        {error && <p className='text-center'>{error}</p>}
    </div>
  );
};

export default AgeCalculatorTool;
