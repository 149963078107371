import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { TextAssistanceContext } from '../context/TextAssistanceContextState';
import WhyUs from '../sections/WhyUs';
import { Helmet } from 'react-helmet';

const TextAssistanceTool = () => {
  const images = useContext(TextAssistanceContext);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Text Assistance Tools | Tiny URLs Creator | Tools Train.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Access text assistance tools at ToolsTrain. From link shortening to document conversion, our free online tools are designed to assist you." />
        <meta name="keywords" content="text assistance, online text tools, language support, writing assistance, text enhancement" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
      <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
          <div className='grid grid-cols-1 mx-[3%] sm:mx-[5%]'>
          <h3 className='pt-8 font-bold text-lg sm:text-xl'>Text Assistance Tools</h3>
            <div className="grid grid-cols-auto-fit gap-2 py-4">
                  {images.map((item, index) => (
                      <Link key={index} to="/" className="border-2 border-Secoundaryv1 rounded-xl px-4 py-5">
                          <img src={item.icon} alt={item.alt} className="w-16 h-auto object-cover border-2 border-Secoundary p-1 rounded-md" />
                          <h3 className='grid grid-cols-1 pt-4 font-semibold'>{item.heading}</h3>
                          <h4 className='grid grid-cols-1 py-2'>{item.description}</h4>
                      </Link>
                  ))}
            </div>
          </div>
      </div>
      <WhyUs/>
    </React.Fragment>
  )
}

export default TextAssistanceTool
