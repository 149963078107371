import React, { useEffect, useState } from 'react'
import LoadingScreen from '../../Utilities/LoadingScreen';
import { useLoading } from '../../context/LoadingContext';
import DummyFileUploader from '../../Utilities/DummyProgressBar';
// This will don't procss the Transparent image
const ImageFile = () => {
  const {showLoading, hideLoading } = useLoading();
  const [ submited, setSubmitt ] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [filename, setfilename] = useState("");
    const [error, setError] = useState(null);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    // new
    const [originalImage, setOriginalImage] = useState(null);
    const [compressedImage, setCompressedImage] = useState(null);
    const [compressionMessage, setCompressionMessage] = useState('');
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setSelectedFile(file);
        // setfilename(file.name.replace(/\.[^/.]+$/, ""));
        const reader = new FileReader();
        reader.onload = (event) => {
          setOriginalImage(event.target.result);
        };

        reader.readAsDataURL(file);
      }
    };
    const compressImage = () => {
      try {
        if (!originalImage) {
          setError("Please select a file first.");
          return;
        }
        setfilename(selectedFile.name.replace(/\.[^/.]+$/, ""));
        setSubmitt(true);
        showLoading();
        const dataUrl = originalImage;
        const img = new Image();
        img.src = dataUrl;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
          let quality = 0.8;
          while (quality > 0.1) {
            const compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
            if (compressedDataUrl.length <= dataUrl.length) {
              setCompressionMessage('');
              setCompressedImage(compressedDataUrl);
              return; // Exit the loop if successful compression
            }
            quality -= 0.1;
          }
          setCompressionMessage('Compression is not possible. Image size would increase.');
          setCompressedImage(null);
        };
      } catch (error) {
        console.error('Error converting PDF to Word:', error.message);
        setError("An error occurred while Compressing the image size.");
      }
      finally{
        setSubmitt(false);
        hideLoading();
      }
    };

    const handleUploadComplete = () => {
      setUploadCompleted(true);
    };
    
    const handleDownloadClick = () => {
      // Trigger download when the Download button is clicked
      if (compressedImage) {
        const link = document.createElement('a');
        link.href = compressedImage;
        link.download = filename + "-compressed.png"; // Adjust the downloaded filename as needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(compressedImage);
      }
    };
  return (
    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
        <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
            <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                Free Image Compressor Online - Without Signup
                <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
            </h2>
            <h3 className='text-center text-sm sm:text-lg'>Free image compressor online - compress your images without the hassle of signing up. Reduce file sizes effortlessly and optimize website performance.</h3>
        </div>
        <div className="grid grid-cols-1 gap-4">
        <label className="grid grid-cols-[auto_auto] justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White">
                <svg className="w-8 h-8" fill="#429FC5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal text-Primary">Select a file</span>
                <input type='file' className="hidden" id="fileInput" accept="image/*" onChange={handleFileChange} />
            </label>
            {selectedFile && uploadCompleted && <p className='text-center'><span className='font-bold text-base sm:text-lg'>File Uploaded:</span> {selectedFile.name}</p>}
            {selectedFile &&  <DummyFileUploader filename={selectedFile.name} onUploadComplete={handleUploadComplete} />}
            {uploadCompleted && <button className="bg-blue-500 text-white p-2 rounded-md border-2 border-Black hover:bg-Black hover:text-White" onClick={compressImage}>Process</button>}
            {compressedImage && <div className='grid grid-cols-1 justify-items-center'>
              <button className="bg-Primary text-white p-2 rounded-md border-2 border-White hover:bg-Black hover:text-White items-end px-4" onClick={handleDownloadClick}>Download</button>
              <p>{filename + "-compressed.jpeg"}</p>
              </div>} 
              {error && <p className="text-red-500">{error}</p>}
            {submited ? <LoadingScreen /> : ""}
          {compressionMessage && (
            <p className="text-red-500">{compressionMessage}</p>
          )}
        </div>
    </div>
  )
}

export default ImageFile



// import React, { useState } from 'react';

// const ImageFile = () => {
//   const [originalImage, setOriginalImage] = useState(null);
//   const [compressedImage, setCompressedImage] = useState(null);
//   const [compressionMessage, setCompressionMessage] = useState('');

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];

//     if (file) {
//       const reader = new FileReader();

//       reader.onload = (event) => {
//         setOriginalImage(event.target.result);
//         compressImage(event.target.result);
//       };

//       reader.readAsDataURL(file);
//     }
//   };

//   const compressImage = (dataUrl) => {
//     const img = new Image();
//     img.src = dataUrl;
//     img.onload = () => {
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//       canvas.width = img.width;
//       canvas.height = img.height;
//       ctx.drawImage(img, 0, 0, img.width, img.height);
//       let quality = 0.8;
//       while (quality > 0.1) {
//         const compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
//         if (compressedDataUrl.length <= dataUrl.length) {
//           setCompressionMessage('');
//           setCompressedImage(compressedDataUrl);
//           return; // Exit the loop if successful compression
//         }
//         quality -= 0.1;
//       }
//       setCompressionMessage('Compression is not possible. Image size would increase.');
//       setCompressedImage(null);
//     };
//   };

//   return (
//     <div className="flex flex-col items-center space-y-4">
//       <input type="file" accept="image/*" onChange={handleImageChange} />
      
//       {originalImage && (
//         <div className="flex flex-col items-center space-y-2">
//           <h2 className="text-lg font-semibold mb-2">Original Image</h2>
//           <img src={originalImage} alt="Original" className="max-w-full" />
//         </div>
//       )}

//       {compressedImage && (
//         <div className="flex flex-col items-center space-y-2">
//           <h2 className="text-lg font-semibold mb-2">Compressed Image</h2>
//           <img src={compressedImage} alt="Compressed" className="max-w-full" />
//         </div>
//       )}

//       {compressionMessage && (
//         <p className="text-red-500">{compressionMessage}</p>
//       )}
//     </div>
//   );
// };

// export default ImageFile;


