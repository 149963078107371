// import React, { useEffect } from 'react';
// import './GoogleTranslate.css'

// const GoogleTranslate = () => {
//   useEffect(() => {
//     // Load Google Translate Element
//     const googleTranslateScript = document.createElement('script');
//     googleTranslateScript.src =
//       '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//     googleTranslateScript.async = true;
//     document.head.appendChild(googleTranslateScript);

//     // Initialize Google Translate Element
//     window.googleTranslateElementInit = () => {
//       new window.google.translate.TranslateElement(
//         { pageLanguage: 'en' }, // Default language is English
//         'google_translate_element'
//       );
//     };

//     return () => {
//       // Clean up script after component unmounts
//       document.head.removeChild(googleTranslateScript);
//       delete window.googleTranslateElementInit;
//     };
//   }, []);

//   return <div id="google_translate_element"></div>;
// };

// export default GoogleTranslate;

import React from 'react'
import './GoogleTranslate.css'
const GoogleTranslate = () => {
  return (
    <div id="google_translate_element" className='grid grid-cols-1 fixed top-16 sm:top-20 right-4 z-50'></div>
  )
}

export default GoogleTranslate
