import React from 'react';
import { Routes, Route } from "react-router-dom";
import { HeaderWithoutLogin } from '../Layout/HeaderWithoutLogin';
import RoutesData from '../Layout/RoutesData';


const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HeaderWithoutLogin />}>
        {RoutesData.map((route, index) => {
          return (
            <>
              <Route
                key={index}
                index={route.index ? true : undefined}
                path={route.path}
                element={route.component}
              />
              {route.Redirect && route.Redirect.map((redirect, redirectIndex) => (
                <Route
                  key={`${index}-${redirectIndex}`}
                  path={redirect.value.props.request}
                  element={redirect.value}
                />
              ))}
            </>
          );
        })}
      </Route>
    </Routes>
  );
};

export { AllRoutes };
