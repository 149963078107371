import React, { useState } from 'react'

export const CloseIcon = ({className}) => {
  const [isHovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(!isHovered);
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
      className={`${className} ${isHovered ? 'hover:' + className : ''}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <path d="M4.7070312 3.2929688L3.2929688 4.7070312L10.585938 12L3.2929688 19.292969L4.7070312 20.707031L12 13.414062L19.292969 20.707031L20.707031 19.292969L13.414062 12L20.707031 4.7070312L19.292969 3.2929688L12 10.585938L4.7070312 3.2929688 z"/>
    </svg>
  );
}
