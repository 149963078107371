import React from 'react'
import '../Utilities/LoadingScreen.css'

const LoadingScreen = () => {
  return (
    // <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center bg-Loading bg-opacity-75 z-50">
    //   <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
    // </div>
    <div className="loading-screen">
      <div className="loader"></div>
    </div>
  )
}

export default LoadingScreen
