import React, { useState, useEffect } from 'react'
import LoadingScreen from '../../Utilities/LoadingScreen';
import { useLoading } from '../../context/LoadingContext';
import DummyFileUploader from '../../Utilities/DummyProgressBar';

const WordtoPdf = () => {
  const {showLoading, hideLoading } = useLoading();
  const [ submited, setSubmitt ] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState(null);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [downloadReady, setDownloadReady] = useState(false);
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        if ( file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'application/msword') {
          setSelectedFile(file);
          setError("");
        } else {
          event.target.value = null;
          setError("Please select a valid Word file.");
        }
      }
    };
    const handleUploadComplete = () => {
      setUploadCompleted(true);
    };
    const PdftoWordConvert = async () => {
      try {
        if (!selectedFile) {
          setError("Please select a file first.");
          return;
        }
        setSubmitt(true);
        showLoading();
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        const response = await fetch('https://toolstrain.xyz/api/FileConvertor/Convert-From-Word', {
          method: 'POST',
          body: formData,
        });
    
        if (!response.ok) {
          const errorResponse = await response.json(); // Assuming the server sends error details as JSON
          throw new Error(`HTTP error! Status: ${response.status}. Message: ${errorResponse.message}`);
        }
    
        // Assuming the server sends the file as an attachment, you can extract the filename from the Content-Disposition header
        //const contentDisposition = response.headers.get('Content-Disposition');
        //const filename = contentDisposition ? contentDisposition.split('filename=')[1] : 'converted-file.pdf';
    
        // Convert the blob data to a downloadable file
        const blobData = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blobData);
        setDownloadUrl(downloadUrl);
        setDownloadReady(true);
      } catch (error) {
        console.error('Error converting PDF to Word:', error.message);
        setError("An error occurred while converting the PDF to Word.");
      }
      finally{
        setSubmitt(false);
      hideLoading();
      }
    }
    const handleDownloadClick = () => {
      // Trigger download when the Download button is clicked
      if (downloadUrl) {
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = selectedFile.name.replace(/\.[^/.]+$/, "") + ".pdf"; // Adjust the downloaded filename as needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      }
    };

  return (
    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
        <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
            <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                Word To Pdf Converter Online Free - Free Tool
                <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
            </h2>
            <h3 className='text-center text-sm sm:text-lg'>Looking for best Word to PDF Converter Online Free? Don’t Worry Toolstrain has got a free document converter just for you!</h3>
        </div>
        <div className="grid grid-cols-1 gap-4">
            <label className="grid grid-cols-[auto_auto] justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White">
                <svg className="w-8 h-8" fill="#429FC5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal text-Primary">Select a file</span>
                <input type='file' className="hidden" id="fileInput" onChange={handleFileChange} />
            </label>
            {selectedFile && uploadCompleted && <p className='text-center'><span className='font-bold text-base sm:text-lg'>File Uploaded:</span> {selectedFile.name}</p>}
            {selectedFile &&  <DummyFileUploader filename={selectedFile.name} onUploadComplete={handleUploadComplete} />}
            {uploadCompleted && <button className="bg-blue-500 text-white p-2 rounded-md border-2 border-Black hover:bg-Black hover:text-White" onClick={PdftoWordConvert}>Process</button>}
            {downloadReady && <div className='grid grid-cols-1 justify-items-center'>
              <button className="bg-Primary text-white p-2 rounded-md border-2 border-White hover:bg-Black hover:text-White items-end px-4" onClick={handleDownloadClick}>Download</button>
              <p>{selectedFile.name.replace(/\.[^/.]+$/, "") + ".pdf"}</p>
              </div>}
            {submited ? <LoadingScreen /> : ""}
            {error && <p className="text-red-500">{error}</p>}
        </div>
    </div>
  )
}

export default WordtoPdf
