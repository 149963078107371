import React from 'react'
import { Helmet } from 'react-helmet';
import Footer from '../Layout/Footer';

const NotFound = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title> Tools Train | 404 Page </title>
        <meta charset="utf-8" />
        <meta name="description" content="Page not Found." />
        <meta name="keywords" content="404, tools train, empty, null" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
      <p className='text-center h-[calc(100vh-430px)] grid grid-cols-1 items-center text-base font-extrabold sm:text-xl'>NOT FOUND !</p>
      <Footer />
    </React.Fragment>
  )
}

export default NotFound
