import xmlbuilder from 'xmlbuilder';
import RoutesData from '../Layout/RoutesData';

const excludedPaths = [
  'profile/:id',
  'edit-profile/:id',
  'delete-bio-profile/:id', 
  '*'
];

export const GenerateSitemap1 = () => {
  const urlset = xmlbuilder.create('urlset', { encoding: 'UTF-8' })
    .att('xmlns', 'http://www.sitemaps.org/schemas/sitemap/0.9')
    .att('xmlns:xhtml', 'http://www.w3.org/1999/xhtml');
  RoutesData.forEach(route => {
    if (excludedPaths.includes(route.path)) {
      return;
    }
    const url = urlset.ele('url');
    url.ele('loc', {}, `https://toolstrain.com${route.path.startsWith('/') ? '' : '/'}${route.path}`);

    if (route.Redirect) {
      route.Redirect.forEach(redirect => {
        const RedirectComponent = redirect.value;
        if (RedirectComponent && RedirectComponent.props && RedirectComponent.props.request) {
          const request = RedirectComponent.props.request;
          url.ele('xhtml:link', {
            href: `https://toolstrain.com${request.startsWith('/') ? '' : '/'}${request}`,
            hreflang: 'x-default',
            rel: 'alternate'
          });
        }
      });
    }
  });

  return urlset.end({ pretty: true });
};
