import React, { useState } from 'react'

export const ShareIcon = ({className}) => {
  const [isHovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(!isHovered);
  };
  // return (
  //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
  //       <path d="M18 2 A 3 3 0 0 0 15 5 A 3 3 0 0 0 15.054688 5.5605469L7.9394531 9.7109375 A 3 3 0 0 0 6 9 A 3 3 0 0 0 3 12 A 3 3 0 0 0 6 15 A 3 3 0 0 0 7.9355469 14.287109L15.054688 18.439453 A 3 3 0 0 0 15 19 A 3 3 0 0 0 18 22 A 3 3 0 0 0 21 19 A 3 3 0 0 0 18 16 A 3 3 0 0 0 16.0625 16.712891L8.9453125 12.560547 A 3 3 0 0 0 9 12 A 3 3 0 0 0 8.9453125 11.439453L16.060547 7.2890625 A 3 3 0 0 0 18 8 A 3 3 0 0 0 21 5 A 3 3 0 0 0 18 2 z" />
  //   </svg>
  // )
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`${className} ${isHovered ? 'hover:' + className : ''}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <path d="M18 2 A 3 3 0 0 0 15 5 A 3 3 0 0 0 15.054688 5.5605469L7.9394531 9.7109375 A 3 3 0 0 0 6 9 A 3 3 0 0 0 3 12 A 3 3 0 0 0 6 15 A 3 3 0 0 0 7.9355469 14.287109L15.054688 18.439453 A 3 3 0 0 0 15 19 A 3 3 0 0 0 18 22 A 3 3 0 0 0 21 19 A 3 3 0 0 0 18 16 A 3 3 0 0 0 16.0625 16.712891L8.9453125 12.560547 A 3 3 0 0 0 9 12 A 3 3 0 0 0 8.9453125 11.439453L16.060547 7.2890625 A 3 3 0 0 0 18 8 A 3 3 0 0 0 21 5 A 3 3 0 0 0 18 2 z" />
    </svg>
  );
}
