import React, { useEffect } from 'react'
import CategorySliderFrame from '../sections/CategorySliderFrame'
import CategorySliderFrame1 from '../sections/CategorySliderFrame1'
import CategorySliderFrame3 from '../sections/CategorySliderFrame3'
// import CategorySliderFrame2 from '../sections/CategorySliderFrame2'
import WhyUs from '../sections/WhyUs'
import { Helmet } from 'react-helmet';

const AllTools = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>All-in-One Tools Collection | Free URL Shortener-Tools Train.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Discover a comprehensive collection of online tools at ToolsTrain. From URLs shortening to document conversion, access all the tools you need in one place." />
        <meta name="keywords" content="all tools, tools train, document conversion, bio link tools, web services" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
      <CategorySliderFrame />
      <CategorySliderFrame1 />
      {/* <CategorySliderFrame2 /> */}
      <CategorySliderFrame3 />
      <WhyUs />
    </React.Fragment>
  )
}

export default AllTools
