import React, {useState} from 'react';

const generatePassword = (length, includeUppercase, includeLowercase, includeNumbers, includeSymbols) => {
  // Define characters based on user selection
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const numberChars = '0123456789';
  const symbolChars = '!@#$%^&*()-=_+[]{}|;:,.<>?';

  let validChars = '';
  validChars += includeUppercase ? uppercaseChars : '';
  validChars += includeLowercase ? lowercaseChars : '';
  validChars += includeNumbers ? numberChars : '';
  validChars += includeSymbols ? symbolChars : '';

  // Generate password
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * validChars.length);
    password += validChars.charAt(randomIndex);
  }

  return password;
};
const PasswordGeneratorTool = () => {
  const [passwordLength, setPasswordLength] = useState(4);
  const [includeUppercase, setIncludeUppercase] = useState(true);
  const [includeLowercase, setIncludeLowercase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSymbols, setIncludeSymbols] = useState(true);
  const [generatedPassword, setGeneratedPassword] = useState('');

  const handleGeneratePassword = () => {
    const password = generatePassword(
      passwordLength,
      includeUppercase,
      includeLowercase,
      includeNumbers,
      includeSymbols
    );
    setGeneratedPassword(password);
  };
  const copyToClipboard = () => {
    const outputField = document.getElementById('genratedPassword');
    if (outputField) {
      outputField.select();
      document.execCommand('copy');
    }
  };
  return (
         <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 content-center gap-8'>
            <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
                <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                    <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                    Strong Random Lastpass Password Generator: Safeguard Your Digital World
                    <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
                </h2>
                <h3 className='text-center text-sm sm:text-lg'>Safeguard your digital world with a strong random Lastpass password generator. Protect your accounts effectively.</h3>
            </div>
            <div className='grid grid-cols-1 w-full sm:w-[36rem] md:w-[46rem] sm:m-auto'>
              <div className='grid grid-cols-1 items-center gap-1'>
                {generatedPassword &&
                  <div className='bg-White grid grid-cols-[1fr_auto] rounded-md gap-4'>
                    <input
                        id='genratedPassword'
                        type='url'
                        value={generatedPassword}
                        className='py-2 sm:py-3 px-4 outline-none w-full rounded-md bg-none'
                        readOnly
                      />
                    <button
                      className='border-2 border-Primary px-2 py-1 my-1 mr-1 rounded-md text-Primary hover:bg-Primary hover:text-White inline-block'
                      onClick={copyToClipboard}
                    >
                      <span className='hidden sm:inline-block'>Quick</span> <span className='inline-block'>Copy</span>
                    </button>
                  </div>
                }
                <button  onClick={handleGeneratePassword}
                  className='border-2 border-Primary px-2 py-2 my-1 mr-1 rounded-md text-White bg-Primary hover:bg-White hover:text-Primary inline-block w-full'
                >
                  <span>Generate</span>
                </button>
              </div>
              <div className="py-3 grid grid-cols-1 gap-3 sm:mb-4">
                <label className="block text-sm font-medium text-gray-600">Length: <span className="text-sm text-gray-600">{passwordLength}</span></label>
                <input
                  type="range"
                  min="4"
                  max="32"
                  value={passwordLength}
                  step="1"
                  onChange={(e) => setPasswordLength(e.target.value)}
                  className="range w-full border border-Light-Primary py-0 h-3 rounded-md cursor-pointer"
                />
              </div>
              <div className='grid grid-cols-1 sm:grid-cols-[auto_auto] gap-4'>
                <div className="grid grid-cols-[auto_1fr] gap-3 items-center">
                  <div className="inline-flex items-center" onChange={() => setIncludeUppercase(!includeUppercase)}>
                    <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                      <input type="checkbox" checked={includeUppercase} onChange={() => setIncludeUppercase(!includeUppercase)} id='includeUppercase'
                        className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-Light-Primary checked:bg-Primary peer-checked:border-White peer-checked:before:bg-whiteborder-White" />
                      <label htmlFor="includeUppercase" 
                        className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-White bg-White shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-Primary before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-White peer-checked:before:bg-Primary">
                        <div className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                          data-ripple-dark="true"></div>
                      </label>
                    </div>
                  </div>
                  <label htmlFor='includeUppercase' className="block text-base font-medium text-gray-600">Include Uppercase</label>
                </div>
                <div className="grid grid-cols-[auto_1fr] gap-3 items-center">
                  <div className="inline-flex items-center" onChange={() => setIncludeLowercase(!includeLowercase)}>
                    <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                      <input type="checkbox" checked={includeLowercase} onChange={() => setIncludeLowercase(!includeLowercase)} id='includeLowercase'
                        className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-Light-Primary checked:bg-Primary peer-checked:border-White peer-checked:before:bg-whiteborder-White" />
                      <label htmlFor="includeLowercase" 
                        className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-White bg-White shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-Primary before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-White peer-checked:before:bg-Primary">
                        <div className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                          data-ripple-dark="true"></div>
                      </label>
                    </div>
                  </div>
                  <label htmlFor='includeLowercase' className="block text-base font-medium text-gray-600">Include Lowercase</label>
                </div>
                <div className="grid grid-cols-[auto_1fr] gap-3 items-center">
                  <div className="inline-flex items-center" onChange={() => setIncludeNumbers(!includeNumbers)}>
                    <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                      <input type="checkbox" checked={includeNumbers} onChange={() => setIncludeNumbers(!includeNumbers)} id='includeNumbers'
                        className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-Light-Primary checked:bg-Primary peer-checked:border-White peer-checked:before:bg-whiteborder-White" />
                      <label htmlFor="includeNumbers" 
                        className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-White bg-White shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-Primary before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-White peer-checked:before:bg-Primary">
                        <div className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                          data-ripple-dark="true"></div>
                      </label>
                    </div>
                  </div>
                  <label htmlFor='includeNumbers' className="block text-base font-medium text-gray-600">Include Numbers</label>
                </div>
                <div className="grid grid-cols-[auto_1fr] gap-3 items-center">
                  <div className="inline-flex items-center" onChange={() => setIncludeSymbols(!includeSymbols)}>
                    <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                      <input type="checkbox" checked={includeSymbols} onChange={() => setIncludeSymbols(!includeSymbols)} id='includeSymbols'
                        className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-Light-Primary checked:bg-Primary peer-checked:border-White peer-checked:before:bg-whiteborder-White" />
                      <label htmlFor="includeSymbols" 
                        className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-White bg-White shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-Primary before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-White peer-checked:before:bg-Primary">
                        <div className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                          data-ripple-dark="true"></div>
                      </label>
                    </div>
                  </div>
                  <label htmlFor='includeSymbols' className="block text-base font-medium text-gray-600">Include Symbols</label>
                </div>
              </div>
            </div>
        </div>
  );
};

export default PasswordGeneratorTool;