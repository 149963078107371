import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const messageRef = useRef(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState('');
  const [contactDetails, setContactDetails] = useState({
    email: '', 
    subject: '', 
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    // contactDetails
    try {
      const response = await fetch('https://toolstrain.xyz/Mail/SendFromBusinessMail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          From_Address: "info@toolstrain.com",
          From_Password: "toolstrain.4321",
          To_Address: "info@toolstrain.com",
          SmtpClientValue: "mail5019.site4now.net",
          Subject: contactDetails.email + " -- " + contactDetails.subject,
          Body: contactDetails.message,
          IsBodyHtml: false,
        }),
      });
  
      if (!response.ok) {
        setError('Please try again.');
        throw new Error('Please try again.');
      }
  
      const result = await response.text();
      setError(result);
  
      setContactDetails({
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error occurred:', error);
      setError('An error occurred while sending the message. Please try again later.');
    }
  };
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us | Toolstrain.com</title>
        <meta charset="utf-8" />
        <meta name="description" content="Review the disclaimer for ToolsTrain. Understand the terms and conditions to ensure a secure and compliant usage of our online tools." />
        <meta name="keywords" content="disclaimer, legal notice, website disclaimer, terms of use, site disclosure" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className='relative container mx-auto max-w-7xl grid grid-cols-1'>
        <div className='grid grid-cols-1 mx-[5%] mt-8 py-4'>
          <div className="bg-gray-100 h-auto flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-md w-full">
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4 text-center text-Primary">Contact Us</h2>
              <p className="text-gray-700 text-center pb-4">
                Feel free to contact us for comments, suggestion, feedback, API purchase and any other inquery. [info@toolstrain.com]
              </p>
              <div>
              <section className="bg-white dark:bg-gray-900">
                <div className="py-8 mx-auto max-w-screen-md">
                    <form className="space-y-8" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm text-Primary font-semibold">Your email address</label>
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="email" placeholder='name@company.com' value={contactDetails.email} onChange={handleChange} name="email" id='email' required />
                        </div>
                        <div>
                            <label htmlFor="subject" className="block mb-2 text-sm text-Primary font-semibold">Subject</label>
                            <input className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' type="text" placeholder="Let us know how we can help you" value={contactDetails.subject} onChange={handleChange} name='subject' id='subject' required/>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message" className="block mb-2 text-sm text-Primary font-semibold">Your message</label>
                            <textarea name="message" ref={messageRef} rows={6} className='border-b-2 focus:border-Primary outline-none bg-All-Primary px-4 py-2 w-full' placeholder="Leave a comment, feedback or inquiry ..." value={contactDetails.message} onChange={handleChange} id='message' required/>
                        </div>
                        <div className='grid grid-cols-1'>
                          <button type='submit' className='border-2 border-Primary rounded-md m-auto px-4 py-2 bg-Primary text-White hover:bg-White hover:text-Primary'>Send message</button>
                        </div>
                    </form>
                    {isSubmit && error!=='' && <p className='text-center py-4 text-Primary'>{error ==="Message sent." ? error + " we will contact you shortly." : error}</p>}
                </div>
              </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ContactUs;
