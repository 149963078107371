import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { P } from '../components/Paragraphs/P';
import { PLink } from '../components/Paragraphs/PLink';
// Also make tis services heading as a link to download Price packedges file
export const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Providing Free Online Tools and Web Services | Toolstrain.com</title>
        <meta charset="utf-8" />
        <meta name="description" content="ToolsTrain offers a variety of free online tools and web services to help users simplify various tasks. Discover our productivity tools." />
        <meta name="keywords" content="" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://toolstrain.com/"
              },
              "headline": "Providing Free Online Tools and Web Services",
              "description": "ToolsTrain offers a variety of free online tools and web services to help users simplify various tasks. Discover our productivity tools.",
              "image": "",  
              "author": {
                "@type": "Organization",
                "name": "toolstrain",
                "url": "https://toolstrain.com/"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "",
                "logo": {
                  "@type": "ImageObject",
                  "url": ""
                }
              },
              "datePublished": "06-11-2024"
          }
        `}
        </script>
        <meta property="og:title" content="Providing Free Online Tools and Web Services" />
        <meta property="og:site_name" content="toolstrain" />
        <meta property="og:url" content="https://toolstrain.com/" />
        <meta property="og:description" content="ToolsTrain offers a variety of free online tools and web services to help users simplify various tasks. Discover our productivity tools." />
        <meta property="og:type" content="" />
        <meta property="og:image" content="" />

        <meta name="twitter:card" content="app" />
        <meta name="twitter:site" content="@toolstrain" />
        <meta name="twitter:description" content="ToolsTrain offers a variety of free online tools and web services to help users simplify various tasks. Discover our productivity tools." />
        <meta name="twitter:app:name:iphone" content="toolstrain" />
        <meta name="twitter:app:id:iphone" content="" />
        <meta name="twitter:app:name:ipad" content="toolstrain" />
        <meta name="twitter:app:id:ipad" content="" />
        <meta name="twitter:app:name:googleplay" content="toolstrain" />
        <meta name="twitter:app:id:googleplay" content="" />
        <meta name="twitter:app:country" content="" />
      </Helmet>
      <div className='relative container mx-auto max-w-7xl grid grid-cols-1'>
        <div className='grid grid-cols-1 mx-[5%] mt-8 py-4'>
          <div className="bg-gray-100 h-auto flex items-center justify-center">
            <div className="grid bg-white p-8 rounded-lg shadow-md w-full gap-3" >
              <h2 className="text-2xl font-semibold mb-4 text-center">About</h2>
              <P txt="ToolsTrain is a platform that We've created to provide a variety of free online tools and web services. Let me share the key points about our company:" />
              <P txt="We offer a 'Free URL Shortener' that allows users to convert lengthy links into short, memorable, and shareable URLs. This can be used for websites, social media, emails, SMS, and more. It's one of our most popular tools." />
              <P txt="We have other free tools as well, including an image-to-text converter, PDF to Word converter, Word to PDF converter, image compressor, QR code generator, age calculator, YouTube thumbnail downloader, password generator, invoice generator, hashtag generator, language encoder/decoder, and PDF word counter. We're always looking to expand our suite of productivity tools." />
              <P txt="All of the tools can be used unlimited times with no restrictions. The results provided are high quality and the tools do not include any watermarks. We're committed to delivering the best possible user experience." />
              <P txt="In addition to the web tools, ToolsTrain also offers a free app for downloading Instagram videos in HD without watermarks. It's been really popular with our users." />
              <P txt="We're committed to providing 'accurate and high quality results' with all of our tools, and users can use them as much as needed with no limits." />
              <P txt="The overall focus for us is on offering a wide variety of free online productivity and content creation tools to help users streamline various tasks. Ease-of-use, quality outputs, and unlimited access are the key principles we've built ToolsTrain around." />
              <ul className='list-disc pl-4'>
                <li>
                  <PLink>This website is for informational and educational purposes only.</PLink>
                </li>
                <li>
                  <PLink>Users are solely responsible for the content they generate using the tools provided on this website.</PLink>
                </li>
                <li>
                  <PLink>We do not guarantee the accuracy, reliability, or completeness of the generated content.</PLink>
                </li>
                <li>
                  <PLink>By using these tools, you agree to the terms and conditions outlined in our Privacy Policy.</PLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}