import React from 'react'

export const Image = ({src, alt}) => {
  return (
    <img
        src={src}
        alt={alt} // eslint-disable-next-line
        className="w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto h-auto rounded-md"
    />
  )
}
