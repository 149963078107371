import React from 'react';
import { AllRoutes } from '../Layout/AllRoutes'; // Assuming AllRoutes.js exports AllRoutes component
import { create } from 'xmlbuilder';
// Future work: https://app.xmlsitemapgenerator.org/en/sitemaps/results?id=b29b2e1e-65e3-4d9b-b9f0-e4dc615c574e

const GenerateSitemap = ({ routes }) => {
  // Sorting routes based on element name
  routes.sort((routeA, routeB) => {
    const elementNameA = getElementName(routeA);
    const elementNameB = getElementName(routeB);
    if (elementNameA === 'RedirectComponent' && elementNameB !== 'RedirectComponent') {
      return -1; // routeA comes first
    } else if (elementNameA !== 'RedirectComponent' && elementNameB === 'RedirectComponent') {
      return 1; // routeB comes first
    } else {
      return 0; // maintain the existing order
    }
  });

  // Define paths to exclude
  const excludedPaths = [
    'all-tools', 
    'document-conversion',
    'web-services',
    'text-assistance-tool',//cccc
    'about',
    'contact-us',
    'disclaimer',
    'terms-and-conditions',
    'privacy-and-policy',
    'image-to-text-convertor',
    'pdf-to-word-convertor',
    'word-to-pdf-convertor',
    'convert-into-png',
    'image-file-compressor',
    'bio',
    'age-calculator',
    'youtube-thumbnail-downloader',
    'password-genrator',
    'hash-tag-genrator',
    'encoder-decoder',
    'words-counter',
    'invoice-genrator',
    'blog', 
    'sitemapgenrator',
    'profile/:id', 
    'delete-bio-profile/:id', 
    'edit-profile/:id',
  ];

  // Filtering out routes based on excluded paths and element name
  const filteredRoutes = routes.filter(route => {
    const { path } = route.props;
    const elementName = getElementName(route);
    return (!excludedPaths.includes(path) && (path && path.trim() !== '')) || (elementName === 'RedirectComponent');
  });

  // Generate sitemap XML
// Generate sitemap XML
const generateXML = () => {
  const xml = create('urlset'); // Providing root element name 'urlset'
  xml.att({
    xmlns: 'http://www.sitemaps.org/schemas/sitemap/0.9',
    'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
    'xsi:schemaLocation': 'http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd'
  });

  filteredRoutes.forEach(route => {
    const { path, lastmod, priority, element } = route.props;
    const loc = `https://toolstrain.com/${path}`;
    const url = xml.ele('url');
    url.ele('loc').txt(loc);
    url.ele('lastmod').txt(lastmod || '2024-03-07T19:58:29+00:00');
    url.ele('priority').txt(priority || '0.50');

    // If the element is RedirectComponent, add an alternative new URL
    if (getElementName(route) === 'RedirectComponent') {
      let { redirectTo } = getElementAttributes(element);
      if(redirectTo==='/'){redirectTo=''}
      const altLoc = `https://toolstrain.com/${redirectTo}`;
      url.ele('xhtml:link', {
        'xmlns:xhtml':"http://www.w3.org/1999/xhtml", 
        // rel: 'alternate',
        rel: 'canonical',
        // hreflang: 'en',
        href: altLoc
      }).txt('');
    }
  });

  return xml.doc().end({ prettyPrint: true, headless: true });
};


  // Download sitemap XML file
  const downloadSitemap = () => {
    const xmlContent = generateXML();
    const blob = new Blob([xmlContent], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sitemap.xml';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
       <div className='grid grid-cols-1 mx-[6%] sm:mx-[5%] '>
        <button onClick={downloadSitemap} className='border border-Primary py-2 px-4'>Download Sitemap</button>
        <div>
          {filteredRoutes.map((route, index) => {
            const { path, priority, lastmod, element } = route.props;
            const elementName = getElementName(route);
            let request = '';
            let redirectTo = '';

            // Extracting request and redirectTo values if element is a component with attributes
            if (typeof element === 'object' && React.isValidElement(element)) {
              const { request: requestValue, redirectTo: redirectToValue } = getElementAttributes(element);
              request = requestValue;
              redirectTo = redirectToValue;
            }

            // Rendering current route
            return (
              <div key={index}>
                <p>Path: {path}</p>
                <p>Priority: {priority}</p>
                <p>Last Modified: {lastmod}</p>
                <p>Element: {elementName}</p>
                {elementName === 'RedirectComponent' && (
                  <div>
                    <p>Request: {request}</p>
                    <p>Redirect To: {redirectTo}</p>
                  </div>
                )}
                <br />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const GenerateSitemapWrapper = () => {
  const AllRoutesData = AllRoutes().props.children;

  const flattenRoutes = (routes) => {
    let flattenedRoutes = [];
    routes.forEach(route => {
      flattenedRoutes.push(route);
      if (route.props.children) {
        const nestedRoutes = flattenRoutes(route.props.children);
        flattenedRoutes = [...flattenedRoutes, ...nestedRoutes];
      }
    });
    return flattenedRoutes;
  };

  const flattenedRoutes = flattenRoutes(AllRoutesData);

  return <GenerateSitemap routes={flattenedRoutes} />;
};

export default GenerateSitemapWrapper;

// Function to extract element name from route
const getElementName = (route) => {
  const { element } = route.props;
  if (typeof element === 'string') {
    return element.replace(/<|>/g, ''); // Extracting component name
  } else if (element && element.type) {
    return element.type.name; // Extracting component name
  }
  return null;
};
const getElementAttributes = (element) => {
  const { props } = element;
  const { request, redirectTo } = props;
  return { request, redirectTo };
};
