import './App.css';
import { BrowserRouter } from "react-router-dom";
import DocConversationState from './context/DocumentConversionContextState';
import WebServicesContextState from './context/WebServicesContextState';
import TextAssistanceContextState from './context/TextAssistanceContextState';
import { LoadingProvider } from './context/LoadingContext';
import ProductivityToolsContextState from './context/ProductivityToolsContextState';
import BlogsState from './context/BlogsContextState';
import { SocialShareProvider } from './context/SocialShareContext';
import { AllRoutes } from './Layout/AllRoutes';
import GoogleTranslate from './Features/GoogleTranslate';

function App() {
  return (
    <BrowserRouter>
      <LoadingProvider>
        <DocConversationState>
          <WebServicesContextState>
            <TextAssistanceContextState>
              <ProductivityToolsContextState>
                <BlogsState>
                  <SocialShareProvider>
                    <AllRoutes />
                    <GoogleTranslate />
                  </SocialShareProvider>
                </BlogsState>
              </ProductivityToolsContextState>
            </TextAssistanceContextState>
          </WebServicesContextState>
        </DocConversationState>
      </LoadingProvider>
    </BrowserRouter>
  )
}

export default App;
