import React, {useContext, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { ProductivityToolsContext } from '../context/ProductivityToolsContextState';
import WhyUs from '../sections/WhyUs';
import { Helmet } from 'react-helmet';

const AllProductiveTools = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const images = useContext(ProductivityToolsContext);
  return (
    <React.Fragment>
      <Helmet>
        <title>Productive Tools | URL Shortener Custom ToolsTrain.</title>
        <meta charset="utf-8" />
        <meta name="description" content="Effortlessly convert documents online with ToolsTrain. PDF to Word, Word to PDF, and more. Simplify document management &enhance productivity. Convert now." />
        <meta name="keywords" content="document conversion, PDF to Word converter, Word to PDF converter, text assistance, document translator" />
        <link rel="canonical" href={window.location.href} />
        {/* Add more meta tags or head elements as needed */}
      </Helmet>
        <div className='relative container mx-auto max-w-7xl min-h-fit grid grid-cols-1'>
            <div className='grid grid-cols-1 mx-[3%] sm:mx-[5%]'>
            <h3 className='pt-8 font-bold text-lg sm:text-xl'>Productive Tools</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 py-4">
                    {images.map((item, index) => (
                        <Link key={index} to={item.ToPath} className="grid grid-cols-1 border-2 border-Secoundaryv1 rounded-xl px-4 py-5 hover:border-Primary">
                            <img src={item.icon} alt={item.alt} className="w-16 h-auto object-cover border-2 border-Secoundary p-1 rounded-md" />
                            <h3 className='line-clamp-2 pt-4 font-semibold'>{item.heading}</h3>
                            <h4 className='line-clamp-3 text-sm sm:text-base my-2'>{item.description}</h4>
                        </Link>
                    ))}
              </div>
            </div>
        </div>
        <WhyUs/>
    </React.Fragment>
  )
}

export default AllProductiveTools;
