import React, { useState, useEffect, useContext, useRef } from 'react'
import Logo from '../assets/logo/ToolsTrainLogo.png';
import { DocConversationContext } from '../context/DocumentConversionContextState';
import { ProductivityToolsContext } from '../context/ProductivityToolsContextState';
import { WebServicesContext } from '../context/WebServicesContextState';
import { Link, useLocation } from 'react-router-dom';
import MenuIconHamburger from '../assets/icons/menu.svg';
import { ArrowDown } from '../assets/icons/ArrowDown';
import { CloseIcon } from '../assets/icons/CloseIcon';

const PrimaryHeader = () => {
    const DocTools = useContext(DocConversationContext);
    const ProTools = useContext(ProductivityToolsContext);
    const WebTools = useContext(WebServicesContext);
    const location = useLocation();
    const [subMenuVisibility, setSubMenuVisibility] = useState(false);
    const [isMobileMenu, setMobileMenu] = useState(false);
    const menuRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (menuRef.current && !menuRef.current.contains(event.target)) {
            // Clicked outside the menu, close the submenu
            setSubMenuVisibility(false);
          }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);
    //   useEffect(() => {
    //     const handleClickOutside = (event) => {
    //       if (apiRef.current && !apiRef.current.contains(event.target)) {
    //         // Clicked outside the menu, close the submenu
    //         setShowApiPage(false);
    //       }
    //     };
    //     document.addEventListener('click', handleClickOutside);
    //     return () => {
    //       document.removeEventListener('click', handleClickOutside);
    //     };
    //   }, []);
    // Screen Dimentions
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
    const updateDimensions = () => {
        setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
        });
    };
    window.addEventListener('resize', updateDimensions);
    return () => {
        window.removeEventListener('resize', updateDimensions);
    };
    }, []); // Empty dependency array ensures that effect runs only on mount and unmount

    const ClickOnHome = () => {
        setSubMenuVisibility(false);
        setMobileMenu(!isMobileMenu);
    }
    const ClickOnBlog = () => {
        setSubMenuVisibility(false);
        setMobileMenu(!isMobileMenu);
    }
    const toggleSubMenuVisibility = () => {
        setSubMenuVisibility(!subMenuVisibility);
        if(dimensions.width<=640){setMobileMenu(false)}
    };
    return (
        <header className='sticky top-0 container mx-auto max-w-7xl grid grid-cols-1 bg-White z-50 rounded-bl-md rounded-br-md'>
            <nav className='grid grid-cols-[auto_auto] justify-between sm:grid-cols-[auto_1fr_auto] items-center justify-items-center mx-[3%] sm:mx-[5%] py-2 bg-white'>
                <div id='Logo' className=''>
                    <Link to="/" className="grid grid-cols-[auto_1fr] items-center gap-2">
                        <img alt='Tools Train Logo Toolstrain.com' src={Logo} className='w-10 h-auto App-logo'/> 
                        <h1 className='uppercase text-Primary font-Special font-[1000] not-italic tracking-wide text-base sm:text-lg'>Tools Train</h1>
                    </Link>
                </div>
                <div id='Menu' className={isMobileMenu && dimensions.width<=640 ? 'grid content-between absolute top-[59.59px] left-0 right-0 bg-Primary text-White h-screen px-[3%] py-[5%] sm:px-[5%]':'hidden sm:grid sm:relative sm:top-0'}>
                    <ul className='grid grid-cols-1 sm:auto-cols-auto sm:grid-flow-col gap-2'>
                        <li className='grid grid-cols-1'>
                            {/*  */}
                            <Link to="/" onClick={ClickOnHome} className={dimensions.width<=640 ? 'grid grid-cols-1 items-center py-2 px-4 border border-White text-White rounded-md' : location.pathname === '/' ? 'grid grid-cols-1 items-center py-2 px-4 border border-Primary text-Primary rounded-md' : 'grid grid-cols-1 items-center py-2 px-4 border border-Transparent hover:border-Primary text-Black hover:text-Primary rounded-md'} aria-current="Page">
                                Home
                            </Link>
                        </li>
                        {/* to="./all-tools" */}
                        <li className='grid grid-cols-1' ref={menuRef}>
                            <button onClick={toggleSubMenuVisibility} className={dimensions.width<=640 ? "grid items-center grid-cols-[auto_auto] justify-between gap-1 py-2 pl-4 pr-2 rounded-md border border-White" : "group grid items-center grid-cols-[auto_auto] gap-1 py-2 pl-4 pr-2 border border-Transparent hover:border-Primary hover:text-Primary hover:rounded-md"}>
                                <span>All Tools</span>
                                <ArrowDown className={dimensions.width<=640 ? 'fill-White w-7 h-auto' : 'group-hover:fill-Primary w-7 h-auto'}/>
                            </button>
                        </li>
                        <li className='grid grid-cols-1'>
                            <Link to="./blog" onClick={ClickOnBlog} className={dimensions.width<=640 ? "grid items-center grid-cols-1 py-2 px-4 border border-White text-White rounded-md" : "grid items-center grid-cols-1 py-2 px-4 border border-Transparent hover:border-Primary hover:text-Primary hover:rounded-md"}>
                                Blog
                            </Link>
                        </li>
                    </ul>
                </div>
                <div id='API' className='grid grid-cols-[auto_auto] sm:grid-cols-[auto] gap-1'>
                    <Link to="./api" className="bg-Primary text-White hover:bg-White hover:text-Primary hover:border-Primary border rounded-tl-xl rounded-br-xl py-1 px-6 text-base sm:text-lg">API</Link>
                    <img className='grid w-8 h-auto sm:hidden' src={MenuIconHamburger} alt='Menu' onClick={()=> setMobileMenu(!isMobileMenu)}/>
                </div>
            </nav>
            {/* min-h-[calc(100vh-100px)] max-h-[calc(100vh + 100px)] */}
            {subMenuVisibility && 
                <div className='absolute top-[62px] left-0 right-0 z-10 bg-Primary text-White grid text-sm bg-white rounded-lg shadow-md mx-[3%] sm:mx-[5%] overflow-auto'>
                    <div className={dimensions.width<=640 ? 'grid grid-cols-[auto_auto] justify-between items-center border-b-2 border-White p-4' : 'grid grid-cols-1 items-center border-b-2 border-White p-4'}>
                        <Link to='./all-tools' className='text-lg sm:text-xl hover:underline' onClick={toggleSubMenuVisibility}>
                            Browse all tools
                        </Link>
                        {dimensions.width<=640 && <div onClick={toggleSubMenuVisibility}>
                            <CloseIcon className='w-8 h-auto fill-White border border-White p-1 rounded-md' />
                        </div>}
                    </div>
                    <div className={dimensions.width<=640 ? "grid grid-cols-1 h-[calc(100vh-100px)]" : "grid sm:grid-cols-2 md:grid-cols-3  h-[calc(100vh-100px)]"}>
                        <div className="p-4 text-gray-900 dark:text-white">
                            <ul className="space-y-3 list-decimal">
                                <Link to="./productivity" onClick={toggleSubMenuVisibility} className="bg-White text-Primary py-2 px-4 rounded-tl-md rounded-tr-md flex items-center hover:underline text-base sm:text-lg group">
                                Productivity Tools
                                </Link>
                                <div className='grid grid-cols-1 pl-4 gap-3'>
                                {
                                    ProTools && ProTools.map((item, index) => (
                                        <li key={index}>
                                            <Link to={item.toPath} onClick={toggleSubMenuVisibility} className=" flex items-center  hover:underline group hover:text-Black">
                                                <p className='line-clamp-1 text-sm sm:text-base'>{item.focusheading}</p>
                                            </Link>
                                        </li>
                                    ))
                                }
                                </div>
                            </ul>
                        </div>
                        <div className="p-4 pb-0 text-gray-900 md:pb-4 dark:text-white">
                            <ul className="space-y-3 list-decimal">
                                <Link to="./document-conversion" onClick={toggleSubMenuVisibility} className="bg-White text-Primary py-2 px-4 rounded-tl-md rounded-tr-md flex items-center hover:underline text-base sm:text-lg group">
                                    Document Conversion
                                </Link>
                                <div className='grid grid-cols-1 pl-4 gap-3'>
                                {
                                    DocTools && DocTools.map((item, index) => (
                                        <li key={index}>
                                            <Link to={item.ToPath} onClick={toggleSubMenuVisibility} className=" flex items-center  hover:underline group hover:text-Black">
                                                <p className='line-clamp-1 text-sm sm:text-base'>{item.focusheading}</p>
                                            </Link>
                                        </li>
                                    ))
                                }
                                </div>
                            </ul>
                        </div>
                        <div className="p-4 pb-5 text-gray-900 md:pb-4 dark:text-white">
                            <ul className="space-y-3 list-decimal">
                                <Link to="./web-services" onClick={toggleSubMenuVisibility} className="bg-White text-Primary py-2 px-4 rounded-tl-md rounded-tr-md flex items-center hover:underline text-base sm:text-lg group">
                                    Web Services
                                </Link>
                                <div className='grid grid-cols-1 pl-4 gap-3'>
                                    {
                                        WebTools && WebTools.map((item, index) => (
                                            <li key={index}>
                                                <Link to={item.toPath} onClick={toggleSubMenuVisibility} className=" flex items-center  hover:underline group hover:text-Black">
                                                    <p className='line-clamp-1 text-sm sm:text-base'>{item.focusheading}</p>
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            }
        </header>
    )
}

export default PrimaryHeader