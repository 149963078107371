import { useEffect } from 'react';

const RedirectComponent = ({ request, redirectTo }) => {
    useEffect(() => {
        if (request) {
            window.location.href = redirectTo;
        }
    }, [request, redirectTo]);
    return null;
};

export default RedirectComponent;
