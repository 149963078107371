import React, { useState, useEffect } from 'react'
import LoadingScreen from '../../Utilities/LoadingScreen';
import { useLoading } from '../../context/LoadingContext';
import DummyFileUploader from '../../Utilities/DummyProgressBar';

const ImageTotext = () => {
    const {showLoading, hideLoading } = useLoading();
    const [ submited, setSubmitt ] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [response, setResponse] = useState("");
    const [error, setError] = useState(null);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };

    const handleUploadComplete = () => {
      setUploadCompleted(true);
    };

    const handleTextExtraction = async () => {
      try {
        if (!selectedFile) {
          alert("Please select a file.");
          return;
        }
        setSubmitt(true);
        showLoading();
        const formData = new FormData();
        formData.append('Image', selectedFile);
  
        const apiResponse = await fetch('https://toolstrain.xyz/ImageToTextConvertor/ImageToText', {
          method: 'POST',
          body: formData,
        });
  
        if (!apiResponse.ok) {
          throw new Error('Error extracting text from image');
        }
  
        const result = await apiResponse.text();
        setResponse(result);
        setError(null);
      } catch (error) {
        setError(error.message);
      } 
      finally{
        setSubmitt(false);
        hideLoading();
      }
    };
  return (
    <div className='relative top-0 left-0 right-0 bottom-0 grid grid-cols-1 gap-8'>
        <div className='grid grid-cols-1 w-full sm:w-3/4 mx-auto justify-items-center items-center gap-4 content-center'>
            {/* <h2 className='text-center  text-White font-bold uppercase before:border-b-2 before:border-White before:pl-2 after:border-b-2 after:border-White after:pl-2'>Url Shortner</h2> */}
            <h2 className='text-center text-2xl sm:text-3xl text-blue-600 font-extrabold uppercase relative'>
                <span className='before:absolute before:content-[""] before:w-16 before:h-3 before:bg-blue-600 before:left-0 before:bottom-0 before:animate-grow before:origin-left'></span>
                Extract Text From Image - Free Image To Text Converter Online OCR
                <span className='after:absolute after:content-[""] after:w-16 after:h-3 after:bg-blue-600 after:right-0 after:bottom-0 after:animate-grow after:origin-right'></span>
            </h2>
            <h3 className='text-center text-sm sm:text-lg'>Extract text from images now with the Toolstrain Free Image to Text Converter. Now, you can easily convert JPG to text, SVG, JPEG, and so on!</h3>
        </div>
        <div className='grid grid-cols-1 mt-4'>
            <div className="grid grid-cols-1 gap-4">
            <label className="grid grid-cols-[auto_auto] justify-self-center gap-2 border-2 border-Primary p-2 rounded-lg bg-White">
                <svg className="w-8 h-8" fill="#429FC5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal text-Primary">Select a file</span>
                <input type='file' className="hidden" id="fileInput" onChange={handleFileChange} />
            </label>
            {selectedFile && uploadCompleted && <p className='text-center'><span className='font-bold text-base sm:text-lg'>File Uploaded:</span> {selectedFile.name}</p>}
            {selectedFile &&  <DummyFileUploader filename={selectedFile.name} onUploadComplete={handleUploadComplete} />}
            {uploadCompleted && <button className="bg-blue-500 text-white p-2 rounded-md border-2 border-Black hover:bg-Black hover:text-White" onClick={handleTextExtraction}>Extract Text</button>}
            {submited ? <LoadingScreen /> : <textarea
                className='grid grid-cols-1 outline-none rounded-md p-2'
                cols={10} rows={10}
                placeholder='Output reflects here...'
                value={response}
                readOnly
            />}
            {error && <p className="text-red-500">{error}</p>}
            </div>
        </div>
    </div>
  )
}

export default ImageTotext
