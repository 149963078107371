
import React from 'react'
import InstagramIcon from '../assets/icons/instagram.svg';
import FacebookIcon from '../assets/icons/facebook.svg';
import LinkedIcon from '../assets/icons/linkedin.svg';
import { CloseIcon } from '../assets/icons/CloseIcon';
import { useSocialShare } from '../context/SocialShareContext';


export const SocialSharePopup = ({hideSocialShare}) => {
    const {PostUrl} = useSocialShare();
    const shareOnLinkedIn = () => {
        const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(PostUrl)}`;
        window.open(linkedinShareUrl, '_blank');
    };
    const shareOnFacebook = () => {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(PostUrl)}`;
        window.open(facebookShareUrl, '_blank');
    };
    const shareOnInstagram = () => {
        // Instagram doesn't provide a direct sharing URL, so you can provide a message to the user to share manually.
        alert("Instagram sharing is not supported directly. Please share manually.");
    };
  return (
    <div className='absolute top-0 left-0 right-0 bottom-0 w-full h-full z-50 bg-TransparentActual'>
        <div className='grid grid-rows-1 grid-flow-col gap-2 w-full h-full items-center justify-center'>
            <div className='inline-block relative border border-Primary rounded-md px-4 py-2'>
                <div className='absolute -top-3 -right-2'>
                    <div className='bg-Primary hover:bg-White border border-Primary rounded-lg group' onClick={hideSocialShare}>
                        <CloseIcon className="fill-White group-hover:fill-Primary w-full h-auto max-w-[28px] p-1"/>
                    </div>
                </div>
                <div className='grid auto-cols-auto grid-flow-col justify-start gap-2'>
                    <button onClick={shareOnLinkedIn} to={PostUrl} target='_blank' rel="noopener noreferrer">
                        <img src={LinkedIcon} className='w-12 h-auto' alt='Linkedin Icon'/>
                    </button>
                    <button onClick={shareOnFacebook} to={PostUrl} target='_blank' rel="noopener noreferrer">
                        <img src={FacebookIcon} className='w-12 h-auto' alt='Facebook Icon'/>
                    </button>
                    <button onClick={shareOnInstagram} to={PostUrl} target='_blank' rel="noopener noreferrer">
                        <img src={InstagramIcon} className='w-12 h-auto' alt='Instagram Icon'/>
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}