import React, { useState } from 'react'

export const ArrowDown = ({className}) => {
  const [isHovered, setHovered] = useState(false);
  const handleHover = () => {
    setHovered(!isHovered);
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
    className={`${className} ${isHovered ? 'hover:' + className : ''}`}
    onMouseEnter={handleHover}
    onMouseLeave={handleHover}
    >
      <path d="M7.4296875 9.5L5.9296875 11L12 17.070312L18.070312 11L16.570312 9.5L12 14.070312L7.4296875 9.5 z" />
    </svg>
  );
}
